import * as t from '../types'

const initialState = {
  options: {},
  validations: {},
  isFetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GET_SELECT_OPTIONS_REQUEST:
      return {
        ...state,
        isFetching: action.payload
      }
    case t.GET_SELECT_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.payload
      }
    default:
      return state
  }
}
