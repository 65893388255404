import React from 'react'
import { useTranslation } from 'react-i18next'

import { InputsContainer } from './styled'

const InputsWrapper = ({ children, title, ...rest }) => {
  const { t } = useTranslation()

  return (
    <InputsContainer {...rest}>
      {!!title &&
        <InputsContainer.Title>{t(`title:${title}`)}</InputsContainer.Title>}
      {children}
    </InputsContainer>
  )
}

export default InputsWrapper
