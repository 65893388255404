import styled from 'styled-components'
import { rgba } from 'polished'

import { Steps } from 'antd'
import { styleguide } from '../../constants'

const { colors } = styleguide
const { Step } = Steps

const AntSteps = styled(Steps)`
  && {
    max-width: 60rem;
  }
`

AntSteps.Step = styled(Step)`

  margin: 0 !important;
  width: 25%;
  cursor: pointer;
  && {
  
    &:first-of-type {

      .ant-steps-item-container {
                
        &:before {
          display: none;
        }
      }
    }
    
    &:last-of-type {
      .ant-steps-item-container {
                
        &:after {
          display: none;
        }
      }    
    }
    
    .ant-steps-item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0;
      position: relative;
      z-index: 10;
      
        &:after,
        &:before {
          display: block;
          content: "";
          position: absolute;
          top: 37%;
          width: 100%;
          height: .2rem;
          background: ${colors.biscay};
          z-index: 5;
        }
        
        &:after {
          left: 50%;
          width: 10000%;
        }
        
        &:before {
          right: 50%;
          width: 10000%;
        }
      
      .ant-steps-item-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 2.8rem;
        height: 2.8rem;
        margin: 0;
        padding-top: .4rem;
        border: none;

        position: relative;
        z-index: 10;
        
        .ant-steps-icon {
          color: ${rgba(colors.white, 0.5)};
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
      
      .ant-steps-item-content {
            
        .ant-steps-item-title {
          font-size: 1.1rem;
          line-height: 1.6rem;
          color: ${rgba(colors.white, 0.5)};
          font-weight: 600;
          padding: 0;
          
          &:after {
            display: none;
          }
        }
      }
    }
    
    &.ant-steps-item-wait {
      
      .ant-steps-item-container {
      
        .ant-steps-item-icon {
          background: ${colors.biscay};
        }
      }
    }
    
    &.ant-steps-item-process {
      
      .ant-steps-item-container {
      
        &:after,
        &:before {
          background: ${colors.yellow};
        }
      
        .ant-steps-item-icon {
          transform: scale(1.1);
          background: ${colors.yellow};
          padding-top: .1rem;

          .ant-steps-icon {
            color: ${colors.black};
          }
        }
        
        .ant-steps-item-content {
            
          .ant-steps-item-title {
            font-size: 1.3rem;
            color: ${colors.yellow};
          }
        }
      }
    }
    
    &.ant-steps-item-finish {
        
      .ant-steps-item-container {
      
        &:after,
        &:before {
          background: ${colors.yellow};
        }

        .ant-steps-item-icon {
          background: ${colors.yellow};
        }
        
        .ant-steps-item-icon {
        
          .ant-steps-icon {
            * {
              fill: ${colors.black};
            }
          }
        }
      }
    }
  }
`

export {
  AntSteps
}
