/**
 *
 * @param type
 * @param bool
 * @returns {{payload: *, type: *}}
 */
export const isFetching = (type, bool) => {
  return {
    type: type,
    payload: bool
  }
}
/**
 *
 * @param type
 * @param field
 * @param regexp
 * @returns {{payload: boolean, type: *}}
 */
export const validateField = (type, field, value, regexp) => {
  return {
    type: type,
    payload: { field: field, error: !regexp.test(value) }
  }
}
