import React from 'react'

import { controls } from '../../constants'
import { getFilteredSelects, validateData } from '../../utils'
import { Icon } from 'antd'
import {
  ContentHeader,
  ContentHeaderActions,
  CalculatorTypes,
  InputsWrapper,
  InputGroup,
  Button,
  ActionsBar
} from '../../components'
import { CalculatorContent } from './internal'
import { Result } from './styled'

const { appSelects, appInputs } = controls

const Calculator = props => {
  const {
    calculator: { tab, isFetching },
    inputs: { app_inputs, app_selects },
    getResultData,
    changeInput,
    changeSelect
  } = props

  const handleInputChange = ({ value }, field) => {
    changeInput({ value: value, field: field })
  }

  const handleSelectChange = (value, field) => {
    changeSelect({ value: value, field: field })
  }

  const handleButtonClick = () => {
    const data = {
      ...getFilteredSelects(appSelects, app_selects, tab),
      ...app_inputs
    }
    getResultData(data, validateData(data, data.connection_type), tab)
  }

  const activeSelects = appSelects.filter((i, idx) => idx <= tab)
    .reduce((acc, el) => {
      const options = el.inputs
        .reduce((accum, i) => ([...accum, i.title]), [])
      return [...acc, ...options]
    }, [])
  // eslint-disable-next-line no-unused-vars
  const res = activeSelects.reduce((acc, el) => {
    return { ...acc, [el]: app_selects[el] }
  }, {})

  return (
    <Result>
      <ActionsBar />
      <ContentHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ContentHeaderActions style={{ marginRight: '4rem' }} actions={['back_to_start']} />
          <CalculatorTypes />
        </div>
        <ContentHeaderActions actions={['open_saved_results', 'save_results']} />
      </ContentHeader>

      <Result.Content>
        <Result.Container>
          <Result.Center>
            <InputsWrapper style={{ width: '100%' }}>
              {appInputs.map(i =>
                <InputGroup
                  type='number'
                  withIcon={i.withIcon}
                  key={i.label}
                  label={i.label}
                  placeholder={i.label}
                  description={i.description}
                  format={i.format && i.format}
                  onValueChange={values => handleInputChange(values, i.label)}
                />
              )}
              <div style={{ display: 'flex', alignItems: 'flex-end', textAlign: 'center' }}>
                <Button onClick={handleButtonClick}>
                  {isFetching
                    ? <Icon type='loading' />
                    : 'calculate'}
                </Button>
              </div>
            </InputsWrapper>
            <CalculatorContent changeSelect={handleSelectChange} style={{ margin: '3.2rem 0  26rem' }} />
          </Result.Center>
        </Result.Container>
      </Result.Content>
    </Result>
  )
}

export default Calculator
