import * as t from '../types'

const changeInput = data => ({
  type: t.HANDLE_INPUT_CHANGE,
  payload: data
})

const changeSelect = data => ({
  type: t.HANDLE_SELECT_CHANGE,
  payload: data
})

const setErrorInput = data => ({
  type: t.SET_ERROR_INPUT,
  payload: data
})

export {
  changeInput,
  setErrorInput,
  changeSelect
}
