import { connect } from 'react-redux'
import ActionsBar from './ActionsBar'

const mapStateToProps = state => ({
  calculator: state.calculator,
  inputs: state.inputs
})

export default connect(
  mapStateToProps,
  null
)(ActionsBar)
