import { connect } from 'react-redux'
import InputGroup from './InputGroup'

const mapStateToProps = state => ({
  result: state.result,
  inputs: state.inputs,
  validations: state.options.validations
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputGroup)
