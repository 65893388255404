import React from 'react'
import { useTranslation } from 'react-i18next'

import { controls } from '../../../../constants'
import { InputsWrapper, InputGroup, Table } from '../../../../components'
import { reset } from '../../../../resources/images'
import { CalculationList } from './internal'
import { Calculator } from './styled'

const { appSelects, appInputs, unavailableSelects } = controls

const CalculatorContent = props => {
  const {
    calculator: {
      tab,
      recommended_products,
      single_product,
      calculation_result
    },
    options: { options },
    inputs: { app_selects },
    changeSelect,
    resetCalculator,
    ...rest
  } = props

  const { t } = useTranslation()

  const resetButton = () => {
    const defaultSelects = Object.keys(options).reduce((acc, el) => {
      return { ...acc, [el]: options[el][0].value }
    }, {})
    const defaultInputs = appInputs.reduce((acc, el) => {
      return { ...acc, [el.label]: el.value }
    }, {})
    resetCalculator({ tab, defaultSelects, defaultInputs })
  }

  const controlSelect = app_selects.connection_type

  return (
    <Calculator {...rest}>
      <Calculator.Aside>
        {controlSelect &&
          <>
            {appSelects.filter((i, idx) => idx <= tab).map(item =>
              <InputsWrapper color='transparent' key={item.title} column title={item.title}>
                {item.inputs.filter(i => !unavailableSelects[controlSelect].includes(i.title)).map(i =>
                  <InputGroup
                    type='select'
                    color='white'
                    key={i.title}
                    withIcon={i.withIcon}
                    description={i.description}
                    label={i.title}
                    options={options[`${i.title}`]}
                    onChange={value => changeSelect(value, i.title)}
                  />
                )}
              </InputsWrapper>
            )}
          </>}
        <div>
          <Calculator.ResetButton
            onClick={resetButton}
          >
            <Calculator.ResetButtonIcon src={reset} />
            {t('buttons:reset_all')}
          </Calculator.ResetButton>
        </div>
      </Calculator.Aside>

      <Calculator.Data>
        {recommended_products.length || single_product.length
          ? <Calculator.TableContent>
            <Calculator.TableContentTitle>{t('table:ergebnisse')}</Calculator.TableContentTitle>
            {!!(recommended_products && recommended_products.length) &&
            <Table title={t('title:suggested_sleeves')} data={recommended_products} />}

            {!!(single_product && single_product.length) &&
            <Table title={t('title:shearing_capacity_specific_item')} data={single_product} />}
          </Calculator.TableContent>
          : <Calculator.Heading>{t('title:no_results')}</Calculator.Heading>
        }

        {!!Object.keys(calculation_result).length && !!Object.keys(calculation_result.edge_distances).length &&
          <CalculationList title={[t('title:edge_distances_1'), t('title:edge_distances_2')]} data={calculation_result.edge_distances} />}

        {!!Object.keys(calculation_result).length && !!Object.keys(calculation_result.edge_distances).length &&
          <CalculationList title={[t('title:mindesteinschraubtiefe')]} single data={calculation_result.mindesteinschraubtiefe} />}

        {/*{tab === 0 && !!Object.keys(calculation_result).length && !!Object.keys(calculation_result.more.basic).length &&*/}
        {/*  <CalculationList className='test' title=['Basic'] data={calculation_result.more.basic} />}*/}

        {/*{tab !== 0 && !!Object.keys(calculation_result).length && !!Object.keys(calculation_result.more.advanced).length &&*/}
        {/*  <CalculationList className='test' title=['Advanced'] data={calculation_result.more.advanced} />}*/}
        <Calculator.DataFooter>
          {t('text:calculated_on_the_basis')}
        </Calculator.DataFooter>
      </Calculator.Data>
    </Calculator>
  )
}

export default CalculatorContent
