import { connect } from 'react-redux'
import CalculationList from './CalculationList'

const mapStateToProps = state => ({
  result: state.result
})

export default connect(
  mapStateToProps,
  null
)(CalculationList)
