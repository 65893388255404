import React from 'react'
import { useTranslation } from 'react-i18next'

import history from '../../../../history'
import { Row, Col } from 'antd'
import { Heading } from '../../../../components'
import { CalcMethodCard } from './internal'
import { CalcMethod } from './styled'
import { calc1, calc2, calc3 } from '../../../../resources/images'

const cards = [
  {
    title: 'tensile_capacity',
    disabled: false,
    method: 0,
    image: calc1
  },
  {
    title: 'shearing_capacity',
    disabled: false,
    method: 1,
    image: calc2
  },
  {
    title: 'combined_capacity',
    disabled: false,
    method: 2,
    image: calc3
  }
]

const CalculationMethod = ({ changeStepWithParams }) => {
  const { t } = useTranslation()
  return (
    <CalcMethod>
      <Heading level={2}>calculation_method_title</Heading>

      <Row style={{ marginTop: '5rem', marginBottom: '8.5rem' }} gutter={32}>
        {cards.map(i =>
          <Col key={i.title} span={8}>
            <CalcMethodCard title={i.title} background={i.image} disabled={i.disabled} method={i.method} onClick={changeStepWithParams} />
          </Col>
        )}
      </Row>
      <CalcMethod.Link onClick={() => history.push('/calculator')}>{t('title:directly_to_the_calculator_tool')}</CalcMethod.Link>
    </CalcMethod>
  )
}

export default CalculationMethod
