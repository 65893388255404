import React from 'react'
import { useTranslation } from 'react-i18next'

import { AntdModal } from './styled'

const Modal = ({ children, description, ...rest }) => {
  const { t } = useTranslation()

  return (
    <AntdModal {...rest}>
      {description
        ? <>
          {children}
            <AntdModal.Description>
              {!!description.texts && !!description.texts.length &&
              description.texts.map((i, idx) =>
                <span style={{ display: 'inline-block' }} key={idx}>{t(`modal_text:${i.text}`)}</span>
              )}

              {!!description.calculation_examples && !!description.calculation_examples.length &&
              description.calculation_examples.map((i, idx) =>
                <>
                  <AntdModal.DescriptionTitle key={idx}>{t(`modal_list_title:${i.title}`)}</AntdModal.DescriptionTitle>
                  <ul>
                    {i.list && i.list.length &&
                    i.list.map((i, idx) =>
                      <li key={idx}>{t(`modal_list_text:${i.text}`)}</li>
                    )}
                  </ul>
                </>)}
            </AntdModal.Description>
        </>
        : <>{children}</>}
    </AntdModal>
  )
}

export default Modal
