import styled from 'styled-components'

const Result = styled.div`
  position: relative;
`

Result.Content = styled.div`
  padding-top: 8rem;
`

Result.Container = styled.div`
  max-width: 116rem;
  margin: 0 auto;
  padding: 0 1rem;
`

Result.Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export {
  Result
}
