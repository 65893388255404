import styled from 'styled-components'

import { styleguide } from '../../constants'

const { colors } = styleguide

const handleColorType = color => {
  switch (color) {
    case 'transparent':
      return 'transparent'
    default:
      return `${colors.whisper}`
  }
}

const InputsContainer = styled.div`
  display: inline-flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  padding: 3.2rem;
  background-color: ${({ color }) => handleColorType(color)}
  border-radius: .6rem;
  
  & > * {
    margin: ${props => props.column ? '0 0 2.4rem 0' : '0 3rem 0 0'};
    width: 27rem;
  }
  & > *:last-child {
    margin: 0;
  }
`

InputsContainer.Title = styled.h4`
  font-size: 1.5rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: ${colors.main};
  border-bottom: .1rem solid ${colors.gray};
  text-transform: uppercase;
  margin: 0 0 1.6rem;
  padding-bottom: .8rem;
`

export {
  InputsContainer
}
