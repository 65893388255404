import styled from 'styled-components'

import { styleguide } from '../../constants'

const { colors } = styleguide

const CalculatorLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > div {
    margin-right: 3.2rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

CalculatorLinks.Item = styled.div`
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: ${props => props.active ? colors.yellow : colors.white};
  position: relative;
  padding: 2.2rem 0;
  border-bottom: .4rem solid ${props => props.active ? colors.yellow : 'transparent'};
  cursor: pointer;
`

export {
  CalculatorLinks
}
