import styled from 'styled-components'
import { rgba } from 'polished'
import { Modal } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

const AntdModal = styled(Modal)`
  && {
    .ant-modal-content {
      background-color: ${colors.white};
    }
    .ant-modal-footer {
      border: none;
      padding: 1.2rem 2.4rem;
    }
    .ant-btn {
      padding: 0 4rem;
      font-weight: 600;
      text-transform: uppercase;
      color: ${rgba(colors.main, 0.4)};
      &:hover {
        color: ${colors.blue};
      }
      &.ant-btn-primary {
        color: ${colors.white};
        &:hover {
          color: ${colors.white};
        }
      }
    }
  }
`

AntdModal.Description = styled.div`
  margin-top: 2.4rem;
  font-size: 1.4rem;
  line-height: 2rem;
`
AntdModal.DescriptionTitle = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 1rem;
`

export {
  AntdModal
}
