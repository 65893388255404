import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import history from './history'
import 'typeface-source-sans-pro'

import { PrivateRoute, Header, Footer } from './components'
import { DashboardPage, CalculatorPage, SavedResult, LoginPage } from './views'

function App () {
  return (
    <main style={{ overflow: 'hidden' }}>
      <Header />
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path='/' component={DashboardPage} />
          <PrivateRoute exact path='/calculator' component={CalculatorPage} />
          <PrivateRoute exact path='/saved-result' component={SavedResult} />

          <Route path='/login' component={LoginPage} />
        </Switch>
      </Router>
      <Footer />
    </main>
  )
}

export default App
