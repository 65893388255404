import http from './http'

const getSelectOptions = select => {
  return http({
    url: `elements/${select}`,
    method: 'GET'
  })
}

export {
  getSelectOptions
}