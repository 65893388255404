import { connect } from 'react-redux'
import actions from '../../../../store/actions'
import CalculationMethod from './CalculationMethod'

const mapDispatchToProps = dispatch => ({
  changeStepWithParams: data => dispatch(actions.dashboard.changeStepWithParams(data))
})

export default connect(
  null,
  mapDispatchToProps
)(CalculationMethod)
