import styled from 'styled-components'

import { Heading } from '../../../../../../components'
import { styleguide } from '../../../../../../constants'

const { colors } = styleguide

const Card = styled.div`
  padding: 5.6rem 5.1rem 6.3rem;
  text-align: center;
  border-radius: .6rem;
  background: ${colors.whisper};
`

Card.Title = styled(Heading)`
  && {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
  }
`

Card.Image = styled.div`
  width: 100%;
  height: 17.6rem;
  border-radius: .6rem;
  background: ${colors.gray} url(${props => props.background}) no-repeat center;
  background-size: cover;
  margin: 2.4rem 0;
`

export {
  Card
}
