import styled from 'styled-components'
import { rgba } from 'polished'
import ReactSVG from 'react-svg'

import { styleguide } from '../../constants'

const { colors } = styleguide

const ActionsList = styled.div`
  position: absolute;
  right: 0;
  top: 45rem;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${colors.blue};
  padding: 3.2rem 2.1rem;
  border-radius: .6rem 0 0 .6rem;
  box-shadow: 0 .4rem 1.6rem ${rgba(44, 44, 44, 0.12)};
`

ActionsList.Item = styled(ReactSVG)`
  cursor: pointer;
  margin-bottom: 2.2rem;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  * {
    fill: ${colors.white};
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover,
  &:focus,
  &:active {
    * {
      fill: ${colors.yellow};
    }
  }
`

export {
  ActionsList
}
