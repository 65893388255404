import React from 'react'
import { useTranslation } from 'react-i18next'

import { AntdButton } from './styled'

const Button = ({ children, large = false, orange, ...rest }) => {
  const { t } = useTranslation()

  return (
    <AntdButton
      large={large ? 1 : 0}
      orange={orange ? 1 : 0}
      {...rest}
    >
      {typeof children === 'string' ? t(`buttons:${children}`) : children}
    </AntdButton>
  )
}

export default Button
