import * as t from '../types'

const initialState = {
  step: 0,
  calc_method: ['tensile_capacity', 'shearing_capacity', 'combined_capacity'],
  method: null,
  article_number: null,
  activeInputs: [],
  isFetching: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.CHANGE_DIRECTLY_STEP:
      return {
        ...state,
        article_number: null,
        step: action.payload.id,
        error: false,
        activeInputs: []
      }
    case t.CHANGE_STEP_WITH_PARAMS:
      return {
        ...state,
        step: action.payload.step,
        method: action.payload.method,
        activeInputs: action.payload.activeInputs ? action.payload.activeInputs : [],
        error: false
      }
    case t.CHECK_ARTICLE_NUMBER_REQUEST:
      return {
        ...state,
        isFetching: action.payload
      }
    case t.CHECK_ARTICLE_NUMBER_FAILURE: {
      return {
        ...state,
        error: !action.payload
      }
    }
    case t.HANDLE_INPUT_CHANGE: {
      return {
        ...state,
        error: false
      }
    }
    case t.CHECK_ARTICLE_NUMBER_SUCCESS:
      return {
        ...state,
        step: action.payload.step,
        article_number: action.payload.article_number,
        activeInputs: action.payload.activeInputs,
        error: false
      }
    case t.BACK_TO_START:
      return {
        ...initialState
      }
    default:
      return state
  }
}
