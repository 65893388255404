import React from 'react'
import { useTranslation } from "react-i18next";

import { Icon } from 'antd'
import { InputGroup, Button } from '../../../../../../components'

import { Card } from './styled'

const ArticleNumberCard = ({ title, input, message, fetching, error, isFetching, inputValue, onChange, onClick }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <Card.Title level={4}>{title}</Card.Title>

      <Card.Content>
        {!!input &&
          <InputGroup
            type='number'
            format='### ### #'
            placeholder={input}
            label={input}
            withErrorDescr
            onValueChange={onChange}
          />}

        {!!message &&
          <Card.Message>{t(`card:${message}`)}</Card.Message>}
      </Card.Content>

      <Button onClick={onClick}>
        {fetching && isFetching
          ? <Icon type='loading' />
          : 'continue'}
      </Button>
    </Card>
  )
}

export default ArticleNumberCard
