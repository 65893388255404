import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { controls } from '../../constants'
import { AntSteps } from './styled'

const steps = [
  {
    title: 'calculation_method',
    id: 0
  },
  {
    title: 'article_number',
    id: 1
  },
  {
    title: 'inputs',
    id: 2
  },
  {
    title: 'result',
    id: 3
  }
]
const { appInputs } = controls

const Steps = props => {
  const {
    dashboard: { step },
    options: { options },
    changeDirectlyStep
  } = props
  const { t } = useTranslation()

  const changeStep = useCallback(id => {
    const defaultSelects = Object.keys(options).reduce((acc, el) => {
      return { ...acc, [el]: options[el][0].value }
    }, {})
    const defaultInputs = appInputs.reduce((acc, el) => {
      return { ...acc, [el.label]: el.value }
    }, {})
    if (step > id) {
      changeDirectlyStep({ id, defaultSelects, defaultInputs })
    }
  }, [step])

  return (
    <AntSteps current={step}>
      {steps.map(s => (
        <AntSteps.Step key={s.id} title={t(`steps:${s.title}`)} onClick={() => changeStep(s.id)} />
      ))}
    </AntSteps>
  )
}

export default Steps
