import React from 'react'

import { PreloaderSpin, PreloaderIcon } from './styled'
const Preloader = () => {
  return (
    <PreloaderSpin
      indicator={
        <PreloaderIcon type='loading' />
      }
    />
  )
}

export default Preloader
