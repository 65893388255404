import styled from 'styled-components'
import { Typography } from 'antd'

const { Title } = Typography

const AntHeading = styled(Title)`
  && {
    margin: 0;
    line-height: 3.2rem;
    text-align: center;
  }
`

export {
  AntHeading
}
