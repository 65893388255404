import { connect } from 'react-redux'
import actions from '../../store/actions'
import PrivateRoute from './PrivateRoute'

const mapStateToProps = state => ({
  options: state.options
})

const mapDispatchToProps = dispatch => ({
  getSelectOptions: selects => dispatch(actions.options.getSelectOptions(selects))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
