import { connect } from 'react-redux'
import Table from './Table'

const mapStateToProps = state => ({
  calculator: state.calculator
})

export default connect(
  mapStateToProps,
  null
)(Table)
