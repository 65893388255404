const selectsType = [
  'materials',
  'classes',
  'load_times',
  'mounting_angle_alpha_1',
  'carrier_material',
  'connection_type',
  'installation_location',
  'thick_component_2_wood',
  'sheet_thickness',
  'screw_strength',
  'angle_beta',
  'power_attack_angle_alpha_2'
  // 'validations'
]

export default {
  selectsType
}
