import http from './http'

const checkArticleNumber = params => {
  return http({
    url: 'products/check',
    method: 'GET',
    params
  })
}

const searchProducts = (data, headers) => {
  return http({
    url: 'search',
    method: 'POST',
    data,
    headers
  })
}

const searchProductsAdvanced = (data, headers) => {
  return http({
    url: 'search/advanced',
    method: 'POST',
    data,
    headers
  })
}

const searchProductsCombined = (data, headers) => {
  return http({
    url: 'search/combined',
    method: 'POST',
    data,
    headers
  })
}

const calculateBasicForm = data => {
  return http({
    url: 'calculate/basic',
    method: 'POST',
    data
  })
}

const calculateAdvancedForm = data => {
  return http({
    url: 'calculate/advanced',
    method: 'POST',
    data
  })
}

const calculateCombinedForm = data => {
  return http({
    url: 'calculate/combined',
    method: 'POST',
    data
  })
}

const checkProducts = data => {
  return http({
    url: 'products/info',
    method: 'POST',
    data
  })
}

export {
  checkArticleNumber,
  searchProducts,
  searchProductsAdvanced,
  searchProductsCombined,
  calculateBasicForm,
  calculateAdvancedForm,
  calculateCombinedForm,
  checkProducts
}
