import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useForm from 'rc-form-hooks'
import moment from 'moment'

import history from '../../history'
import { validateData } from '../../utils'
import { i18n } from '../../services'
import { controls } from '../../constants'
import { Form, Input, notification } from 'antd'
import { Modal, Button } from '../'
import { save, saved, back } from '../../resources/images'
import { Actions } from './styled'

const { appInputs } = controls

const calculatorType = [
  {
    title: 'tensile_capacity'
  },
  {
    title: 'shearing_capacity'
  },
  {
    title: 'combined_capacity'
  }
]

const ContentHeaderActions = props => {
  const buttons = [
    {
      title: 'open_saved_results',
      icon: saved,
      onClick: () => history.push('/saved-result')
    },
    {
      title: 'save_results',
      icon: save,
      onClick: () => handleButtonClick()
    },
    {
      title: 'back_to_calculator',
      icon: back,
      onClick: () => history.push('/calculator')
    },
    {
      title: 'back_to_start',
      icon: back,
      onClick: () => handleBackToStart()
    }
  ]

  const {
    isHidden,
    actions = [],
    calculator: {
      tab,
      recommended_products,
      single_product,
      calculation_result: { edge_distances, mindesteinschraubtiefe }
    },
    options: { options },
    inputs: { app_inputs, app_selects },
    saveHistoryRecord,
    backToStart,
    ...rest
  } = props

  const { t } = useTranslation()
  const { getFieldDecorator, validateFields, setFieldsValue, values } = useForm()
  const [visible, setVisible] = useState(false)

  const handleButtonClick = () => {
    if (!recommended_products.length && !single_product.length) {
      notification.error({
        message: t('notifications:nothing_to_save')
      })
      return null
    }
    setFieldsValue({
      name: `${moment().format('YYDDMM-HH:mm')}-${t(`title:${calculatorType.find((i, idx) => idx === tab).title}`)}`
    })
    setVisible(!visible)
  }

  const handleBackToStart = () => {
    const defaultSelects = Object.keys(options).reduce((acc, el) => {
      return { ...acc, [el]: options[el][0].value }
    }, {})
    const defaultInputs = appInputs.reduce((acc, el) => {
      return { ...acc, [el.label]: el.value }
    }, {})
    backToStart({ tab: 0, defaultSelects, defaultInputs })
  }

  const handleCloseModal = () => {
    setVisible(false)
  }

  const handleSaveResult = e => {
    e.preventDefault()
    const data = {
      language: i18n.language.split('-')[0],
      recommended_products: recommended_products,
      single_product: single_product,
      edge_distance: edge_distances,
      mindesteinschraubtiefe: mindesteinschraubtiefe,
      calculator_type: tab,
      inputs: app_inputs,
      selects: validateData(app_selects, app_selects.connection_type)
    }
    validateFields()
      .then(() => {
        saveHistoryRecord({ parameters: values, result: JSON.stringify(data) })
        setVisible(!visible)
        notification.success({
          message: t('notifications:the_result_is_stored')
        })
      })
  }

  return (
    <>
      <Actions isHidden={isHidden ? 1 : 0} {...rest}>
        {buttons.filter(i => actions.includes(i.title)).map(i =>
          <Actions.Button onClick={i.onClick} key={i.title}>
            <Actions.ButtonIcon src={i.icon} />
            {t(`buttons:${i.title}`)}
          </Actions.Button>
        )}
      </Actions>
      {visible &&
        <Modal
          visible
          onCancel={handleCloseModal}
          onOk={handleSaveResult}
        >
          <Form style={{ paddingTop: '1rem' }} onSubmit={handleSaveResult}>
            <Form.Item label={`${t('modal:result_name')}:`}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: t('modal:input_result_name') }],
              })(
                <Input />
              )}
            </Form.Item>
          </Form>
        </Modal>}
    </>
  )
}

export default ContentHeaderActions
