import http from './http'

const userLogin = data => {
  return http({
    url: 'login',
    method: 'POST',
    data
  })
}

const checkUserLogin = () => {
  return http({
    url: 'login/check',
    method: 'GET'
  })
}

export {
  userLogin,
  checkUserLogin
}
