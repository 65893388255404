import * as t from '../types'
import history from '../../history'

const changeActiveTab = data => ({
  type: t.CHANGE_ACTIVE_TAB,
  payload: data
})

const resetCalculator = data => ({
  type: t.RESET_CALCULATOR,
  payload: data
})

const backToStart = data => dispatch => {
  dispatch({
    type: t.BACK_TO_START,
    payload: data
  })
  history.push('/')
}

export {
  changeActiveTab,
  resetCalculator,
  backToStart
}
