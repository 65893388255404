import React from 'react'

import { Button } from '../../../../../../components'
import { Card } from './styled'

const CalcMethodCard = ({ title, background, image, method, disabled, onClick }) => {

  return (
    <Card>
      <Card.Title level={4}>{title}</Card.Title>

      <Card.Image background={background} />

      <Button disabled={disabled} onClick={() => onClick({ step: 1, method: method })}>continue</Button>
    </Card>
  )
}

export default CalcMethodCard
