import * as t from '../types'

const initialState = {
  list: [],
  isFetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GET_HISTORY_USER_RECORD_REQUEST:
      return {
        ...state,
        isFetching: action.payload
      }
    case t.GET_HISTORY_USER_RECORD_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case t.REMOVE_HISTORY_RECORD_REQUEST:
      return {
        ...state,
        isFetching: action.payload
      }
    case t.REMOVE_HISTORY_RECORD_SUCCESS:
      return {
        ...state,
        list: state.list.filter(i => i.id !== action.payload.record_id)
      }
    default:
      return state
  }
}
