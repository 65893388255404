import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { rgba } from 'polished'

import { styleguide } from '../../constants'

const { colors } = styleguide

const AlertBlock = styled.div`
  text-align: center;
  padding: 2.4rem 3.2rem;
  margin-bottom: 3.2rem;
  border: .1rem solid ${colors.burnt_sienna};
  border-radius: .6rem;
  background-color: ${rgba(colors.burnt_sienna, 0.15)};
`

AlertBlock.Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 600;
`

AlertBlock.Description = styled.div`
  text-align: center; 
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  margin-top: 1rem;
`

AlertBlock.TitleIcon = styled(ReactSVG)`
  width: 2.4rem;
  height: 2.2rem;
  margin-right: 1rem;
`

export {
  AlertBlock
}
