import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Heading } from '../'
import { AntdTable } from './styled'

const Table = props => {
  const {
    calculator: { tab },
    data = [],
    title
  } = props
  const { t } = useTranslation()
  const [expandedRowKey, setExpandedRowKey] = useState([])

  const handleExpandedRow = rowKey => {
    if (expandedRowKey.includes(rowKey)) {
      setExpandedRowKey(expandedRowKey.filter(i => i !== rowKey))
    } else {
      setExpandedRowKey([...expandedRowKey, rowKey])
    }
  }

  const columns = [
    {
      title: t('table:article_number'),
      key: 'article_number',
      activeTab: [0, 1, 2],
      render: record => <a onClick={() => handleExpandedRow(record.code)}>{record.code}</a>
    },
    {
      title: t('table:tensile_capacity'),
      key: 'tensile_capacity',
      activeTab: [0, 1, 2],
      render: record => Math.round(record.tension_resistance)
    },
    {
      title: t('table:shear_load_capacity'),
      key: 'shear_load_capacity',
      activeTab: [1, 2],
      render: record => Math.round(record.shear_load_capacity)
    },
    {
      title: t('table:combined_load_capacity'),
      key: 'combined_load_capacity',
      activeTab: [2],
      render: record => Math.round(record.combined_load_capacity)
    }
  ]

  const filteredColumns = columns.filter(i => {
    if (i.activeTab.includes(tab)) {
      return i
    } else {
      return null
    }
  })

  return (
    <AntdTable
      columns={filteredColumns}
      dataSource={data}
      pagination={false}
      rowKey='code'
      rowClassName={(record, index) => (index % 2) ? 'row-even' : 'row-odd'}
      locale={{ emptyText: t('table:no_data') }}
      // expandIconAsCell={false}
      // expandIconColumnIndex={-1}
      // expandedRowRender={record => <p style={{ margin: 0 }}>{record.code}</p>}
      // expandedRowKeys={expandedRowKey}
      title={() => (
        <Heading style={{ lineHeight: '2.4rem', fontWeight: 'bold' }} level={4}>{title}</Heading>
      )}
    />
  )
}

export default Table
