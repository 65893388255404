import { connect } from 'react-redux'
import actions from '../../store/actions'
import Steps from './Steps'

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  options: state.options
})

const mapDispatchToProps = dispatch => ({
  changeDirectlyStep: step => dispatch(actions.dashboard.changeDirectlyStep(step))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Steps)
