import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'

import { i18n } from '../../../../services'
import { Heading, Alert } from '../../../../components'
import { ArticleNumberCard } from './internal'
import { ArticleNumberBlock } from './styled'

const cards = [
  {
    title: 'yes',
    input: 'article_number',
    message: '',
    fetching: true,
    with_article: true,
    activeInputs: ['article_number']
  },
  {
    title: 'no',
    input: '',
    message: 'card_without_article',
    with_article: false,
    activeInputs: ['deviation', 'target_load_capacity']
  }
]

const ArticleNumber = props => {
  const {
    dashboard: { calc_method, method, isFetching },
    inputs: {
      app_inputs: { article_number },
      errors
    },
    setArticleNumber,
    changeInput,
    changeStepWithParams
  } = props
  const { t } = useTranslation()

  const handleInputChange = ({ value }, field) => {
    changeInput({ value: value, field: field })
  }

  const onButtonClick = (type, activeInputs) => {
    if (type) {
      setArticleNumber({ step: 2, activeInputs: activeInputs, article_number: article_number }, { code: article_number })
    } else {
      changeStepWithParams({ step: 2, activeInputs: activeInputs, article_number: null, method: method })
    }
  }

  const activeLanguage = i18n.language.split('-')[0]

  return (
    <ArticleNumberBlock>
      <Heading level={2}>{calc_method[method]}</Heading>

      <ArticleNumberBlock.Heading>
        {activeLanguage === 'en'
          ? t('title:proceed_with_the_calculation')
          : <>
            {t('title:do_you_want_to_calculate')}&nbsp;
            {t(`title:${calc_method[method]}`)}&nbsp;
            {t('title:for_a_particular_article')}
          </>}
      </ArticleNumberBlock.Heading>

      <ArticleNumberBlock.CardBox>
        <Alert
          title='title'
          message='message'
          visible={errors['article_number']}
        />

        <Row gutter={32}>
          {cards.map(i =>
            <Col key={i.title} span={12}>
              <ArticleNumberCard
                title={i.title}
                input={i.input}
                message={i.message}
                fetching={i.fetching}
                value={article_number}
                isFetching={isFetching}
                onChange={values => handleInputChange(values, i.input)}
                onClick={() => onButtonClick(i.with_article, i.activeInputs)}
              />
            </Col>
          )}
        </Row>
      </ArticleNumberBlock.CardBox>
    </ArticleNumberBlock>
  )
}

export default ArticleNumber
