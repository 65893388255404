import styled from 'styled-components'
import { rgba } from 'polished'
import { Table } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

const AntdTable = styled(Table)`
  && {
    margin-top: 1.6rem;
    .ant-table-title {
      padding: 1.6rem 0 0.8rem;
      border-radius: .6rem .6rem 0 0;
      background: ${rgba(colors.white, 0.2)};
    }
    .ant-table-wrapper,
    .ant-table-placeholder {
      border-color: ${colors.gray};
      padding: 0;
      border-radius: 0 !important;
    }
    .ant-table-placeholder {
      border-radius: .6rem !important;
      padding: 1.6rem !important;
      background-color: ${rgba(colors.white, 0.2)};
      border-color: transparent;
      color: ${colors.main};
    }
    .ant-table-thead,
    .ant-table-body {
      * {
        text-align: center;
        background-color: transparent;
        line-height: 1.6rem;
        border: none;
      }
    }
    .ant-table-thead {
      * {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.6rem;
      }
    }
    .ant-table-tbody {
      tr.row-odd {
        td {
          background-color: ${rgba(colors.white, 0.2)};
        }
        &:hover {
          td {
            background-color: ${rgba(colors.white, 0.2)};
          }        
        }
      }
      tr.row-even {
        td {
          background-color: transparent;
        }
        &:hover {
          td {
            background-color: transparent;
          }        
        }
      }
    }    
  }
`

export {
  AntdTable
}
