import React from 'react'
import { useTranslation } from 'react-i18next'

import { alert } from '../../resources/images'

import { AlertBlock } from './styled'
const Alert = props => {
  const {
    visible = false,
    title = '',
    message = '',
    ...rest
  } = props
  const { t } = useTranslation()

  return (
    <>
      {visible &&
        <AlertBlock {...rest}>
          {!!title &&
            <AlertBlock.Title>
              <AlertBlock.TitleIcon src={alert} />
              {t(`alert_article:${title}`)}
            </AlertBlock.Title>}
          {!!message &&
            <AlertBlock.Description>
              {t(`alert_article:${message}`).split('.').map(i => <p style={{ marginBottom: 0 }} key={i}>{i}.</p>)}
            </AlertBlock.Description>}
        </AlertBlock>}
    </>
  )
}

export default Alert
