/**
 * An object is created with a selection by active step or tab
 * @param appSelects array
 * @param storeAppSelects object
 * @param calculatorIndex number
 * @returns {*}
 */
export const getFilteredSelects = (appSelects, storeAppSelects, calculatorIndex) => {
  return appSelects.filter((i, idx) => idx <= calculatorIndex)
    .reduce((acc, el) => {
      const objByEl = el.inputs
        .reduce((middleAcc, i) => ({ ...middleAcc, [i.title]: storeAppSelects[i.title] }), {})

      return { ...acc, ...objByEl }
    }, {})
}

/**
 * Copy text content to clipboard
 * @param textContent
 */
export const copy2Clipboard = (textContent) => {
  const textarea = document.createElement('textarea')

  textarea.style.width = '1px'
  textarea.style.height = '1px'
  textarea.style.position = 'fixed'
  textarea.style.top = '-1px'
  textarea.style.left = '-1px'

  textarea.textContent = textContent

  document.body.appendChild(textarea)
  try {
    textarea.select()
    document.execCommand('copy')
  } catch (error) {
    console.error(error)
  }
  document.body.removeChild(textarea)
}
/**
 *
 * @param stateData
 * @param type
 * @returns {{carrier_material: boolean, thick_component_2_wood: boolean}|*|{sheet_thickness: boolean}|{sheet_thickness: boolean, carrier_material: boolean, thick_component_2_wood: boolean}}
 */
export const validateData = (stateData, type) => {
  switch (type) {
    case 'Holz':
      return {
        ...stateData,
        sheet_thickness: false
      }
    case 'Blech':
      return {
        ...stateData,
        carrier_material: false,
        thick_component_2_wood: false
      }
    case 'Seilöse':
      return {
        ...stateData,
        sheet_thickness: false,
        carrier_material: false,
        thick_component_2_wood: false
      }
    default:
      return stateData
  }
}

/**
 *
 * @param field
 * @param regexp
 * @returns {*}
 */
export const validateInputs = (field, regexp) => {
  return regexp.test(field)
}

export const targetLoadCapacityRegexp = /^([1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-3][0-9]{4}|40000)$/
export const deviationRegexp = /^([1-8][0-9]|9[0-9]|100)$/
