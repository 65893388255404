import { connect } from 'react-redux'
import DashboardPage from './DashboardPage'

const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(
  mapStateToProps,
  null
)(DashboardPage)
