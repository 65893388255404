import { connect } from 'react-redux'
import ArticleNumberCard from './ArticleNumberCard'
import actions from '../../../../../../store/actions'

const mapDispatchToProps = dispatch => ({
  changeInput: data => dispatch(actions.inputs.changeInput(data))
})

export default connect(
  null,
  mapDispatchToProps
)(ArticleNumberCard)
