import axios from 'axios'

import { status, token, connection } from './interceptors'

import { config } from '../../../constants'

const instance = axios.create({
  baseURL: config.baseURL,
  data: {},
  timeout: 180000,
  mode: 'no-cors'
})
token(instance)
connection(instance)
status(instance)

export default instance
