import * as dashboard from './dashboard'
import * as options from './options'
import * as calculator from './calculator'
import * as inputs from './inputs'
import * as result from './result'

export default {
  dashboard,
  options,
  calculator,
  inputs,
  result
}
