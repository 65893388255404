import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { copy2Clipboard, validateData } from '../../utils'
import { api, i18n } from '../../services'
import { Tooltip, notification } from 'antd'
import { ActionsList } from './styled'
import { print, share, download } from '../../resources/images'
const actions = [
  {
    title: 'print',
    icon: print,
    type: 'download'
  },
  {
    title: 'share',
    icon: share,
    type: 'clippy'
  },
  {
    title: 'download',
    icon: download,
    type: 'download'
  }
]

const calculatorType = [
  {
    title: 'tensile_capacity'
  },
  {
    title: 'shearing_capacity'
  },
  {
    title: 'combined_capacity'
  }
]

const ActionsBar = props => {
  const {
    calculator: {
      tab,
      recommended_products,
      single_product,
      calculation_result: { edge_distances, mindesteinschraubtiefe }
    },
    inputs: { app_inputs, app_selects }
  } = props
  const { t } = useTranslation()

  const handleClick = type => {
    const data = {
      language: i18n.language.split('-')[0],
      recommended_products: recommended_products,
      single_product: single_product,
      edge_distance: edge_distances,
      mindesteinschraubtiefe: mindesteinschraubtiefe,
      calculator_type: tab,
      inputs: app_inputs,
      selects: validateData(app_selects, app_selects.connection_type)
    }
    const name = `${moment().format('YYDDMM-HH:mm')}-${t(`title:${calculatorType.find((i, idx) => idx === tab).title}`)}`
    if (!recommended_products.length && !single_product.length) {
      notification.error({
        message: t('notifications:calculation_result_is_empty')
      })
      return null
    }
    api.result.getPDFLink({ name: name, data: JSON.stringify(data) }).then(({ data }) => {
      if (type === 'download') {
        window.open(data.link, '_target')
      } else {
        copy2Clipboard(data.link)
        notification.success({
          message: t('notifications:link_copied_to_clipboard')
        })
      }
    })
  }

  return (
    <ActionsList>
      {actions.map(i =>
        <Tooltip key={i.title} placement='left' title={t(`buttons:${i.title}`)}>
          <ActionsList.Item onClick={() => handleClick(i.type)} src={i.icon} />
        </Tooltip>
      )}
    </ActionsList>
  )
}

export default ActionsBar
