// user
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'

// steps
export const CHANGE_DIRECTLY_STEP = 'CHANGE_DIRECTLY_STEP'
export const CHANGE_STEP_WITH_PARAMS = 'CHANGE_STEP_WITH_PARAMS'

// tab
export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB'
export const CHOOSE_ACTIVE_TAB = 'CHOOSE_ACTIVE_TAB'

// article number
export const CHECK_ARTICLE_NUMBER_REQUEST = 'CHECK_ARTICLE_NUMBER_REQUEST'
export const CHECK_ARTICLE_NUMBER_SUCCESS = 'CHECK_ARTICLE_NUMBER_SUCCESS'
export const CHECK_ARTICLE_NUMBER_FAILURE = 'CHECK_ARTICLE_NUMBER_FAILURE'

// select options
export const GET_SELECT_OPTIONS_REQUEST = 'GET_SELECT_OPTIONS_REQUEST'
export const GET_SELECT_OPTIONS_SUCCESS = 'GET_SELECT_OPTIONS_SUCCESS'
export const GET_SELECT_OPTIONS_FAILURE = 'GET_SELECT_OPTIONS_FAILURE'

export const SET_RESULT_INPUTS = 'SET_RESULT_INPUTS'
export const CHECK_INPUTS_FAILURE = 'CHECK_INPUTS_FAILURE'

export const GET_RESULT_DATA_REQUEST = 'GET_RESULT_DATA_REQUEST'
export const GET_RESULT_DATA_SUCCESS = 'GET_RESULT_DATA_SUCCESS'
export const GET_RESULT_DATA_FAILURE = 'GET_RESULT_DATA_FAILURE'

export const RESET_CALCULATOR = 'RESET_CALCULATOR'
export const BACK_TO_START = 'BACK_TO_START'

export const HANDLE_INPUT_CHANGE = 'HANDLE_INPUT_CHANGE'
export const HANDLE_SELECT_CHANGE = 'HANDLE_SELECT_CHANGE'
export const SET_ERROR_INPUT = 'SET_ERROR_INPUT'

// history result
export const GET_HISTORY_USER_RECORD_REQUEST = 'GET_HISTORY_USER_RECORD_REQUEST'
export const GET_HISTORY_USER_RECORD_SUCCESS = 'GET_HISTORY_USER_RECORD_SUCCESS'
export const SAVE_HISTORY_RECORD_REQUEST = 'SAVE_HISTORY_RECORD_REQUEST'
export const SAVE_HISTORY_RECORD_SUCCESS = 'SAVE_HISTORY_RECORD_SUCCESS'
export const REMOVE_HISTORY_RECORD_REQUEST = 'REMOVE_HISTORY_RECORD_REQUEST'
export const REMOVE_HISTORY_RECORD_SUCCESS = 'REMOVE_HISTORY_RECORD_SUCCESS'
