import React from 'react'
import { useTranslation } from 'react-i18next'

import { i18n } from '../../services'

import './Footer.scss'

export default function () {
  const { t } = useTranslation()

  const getFooterLinksByLanguage = language => {
    switch (language) {
      case 'en':
        return {
          imprint: 'https://www.rampa.com/de/en/content/7-Imprint',
          privacy: 'https://www.rampa.com/de/en/content/8-data-protection-notice'
        }
      case 'de':
        return {
          imprint: 'https://www.rampa.com/de/de/content/7-impressum',
          privacy: 'https://www.rampa.com/de/de/content/8-Datenschutzhinweise'
        }
      default:
        return {
          imprint: 'https://www.rampa.com/de/en/content/7-Imprint',
          privacy: 'https://www.rampa.com/de/en/content/8-data-protection-notice'
        }
    }
  }

  return (
    <footer className='footer'>
      <a
        className='footer__link'
        href={getFooterLinksByLanguage(i18n.language.split('-')[0]).imprint}
        target='_blank'
        rel='noopener noreferrer'
      >
        {t('footer:imprint')}
      </a>

      <a
        className='footer__link'
        href={getFooterLinksByLanguage(i18n.language.split('-')[0]).privacy}
        target='_blank'
        rel='noopener noreferrer'
      >
        {t('footer:privacy')}
      </a>
    </footer>
  )
}