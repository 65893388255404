export default {
  general: {
    calculator_tool: 'ETA CALCULATOR',
    online_shop: 'ONLINE SHOP'
  },
  buttons: {
    continue: 'weiter',
    calculate: 'BERECHNEN',
    open_saved_results: 'Öffne gespeicherte Ergebnisse',
    save_results: 'Ergebnisse speichern',
    back_to_calculator: 'Zurück zum Kalkulator',
    back_to_start: 'Zurück zum Start',
    reset_all: 'Werte zurücksetzen',
    print: 'Ergebnisse drucken',
    share: 'Ergebnisse tellen',
    download: 'Ergebnisse herunterladen (PDF)',
    remove: 'Löschen',
    log_in: 'Anmelden'
  },
  input_label: {
    article_number: 'Artikelnummer',
    target_load_capacity: 'Soll-Tragfähigkeit',
    deviation: 'Abweichung vom Sollwert'
  },
  select_label: {
    article_number: 'Artikelnummer',
    target_load_capacity: 'Soll-Tragfähigkeit N',
    deviation: 'Abweichung',
    materials: 'Werkstoff',
    classes: 'Nutzungsklasse',
    load_times: 'Lasteinwirkungsdauer',
    mounting_angle_alpha_1: 'Einschraubwinkel Alpha',
    carrier_material: 'Holzwerkstoff Bauteil 2',
    connection_type: 'Werkstoffart Bauteil 2',
    installation_location: 'Einbauort',
    thick_component_2_wood: 'Dicke des 2. Bauteils',
    sheet_thickness: 'Blechdicke',
    screw_strength: 'Schraubenfestigkeit',
    angle_beta: 'Winkel Beta des 2. Bauteils',
    power_attack_angle_alpha_2: 'Kraftangriffswinkel Alpha2'
  },
  steps: {
    calculation_method: 'Berechnungsmethode',
    article_number: 'Artikelnummer',
    inputs: 'Eingaben',
    result: 'Ergebnis'
  },
  title: {
    calculation_method_title: 'Wählen Sie die gewünschte Berechnungsmethode?',
    tensile_capacity: 'Zugtragfähigkeit',
    shearing_capacity: 'Abschertragfähigkeit',
    combined_capacity: 'Kombinierte Tragfähigkeit',
    particular_article: 'Möchten Sie die Tragfähigkeit für einen bestimmten Artikel berechnen lassen?',
    saved_results: 'Gespeicherte Ergebnisse',
    login_to_the_calculator_tool: 'Anmelden im ETA-Calculator',
    no_saved_results: 'Keine gespeicherten Ergebnisse.',
    do_you_want_to_delete: 'Möchten Sie die Datei löschen?',
    yes: 'JA',
    no: 'NEIN',
    result_ETA: 'Ergebnisse, berechnet auf Grundlage der ETA 12/0481',
    directly_to_the_calculator_tool: 'Daten direkt eingeben',
    shearing_capacity_specific_item: 'Tragfähigkeitssuche nach Artikelnummer',
    suggested_sleeves: 'Empfohlene Muffen',
    edge_distances_1: 'Randabstände',
    edge_distances_2: 'für die eingegebene Artikelnummer',
    mindesteinschraubtiefe: 'Mindesteinschraubtiefe',
    do_you_want_to_calculate: 'Möchten Sie die',
    for_a_particular_article: 'für einen bestimmten Artikel berechnen?',
    no_results: 'Bitte passen Sie die Parameter an und starten Sie eine neue Kalkulation, um Ergebnisse zu erzielen. '
  },
  notifications: {
    calculation_result_is_empty: 'Keine Resultate für diese Berechnung',
    link_copied_to_clipboard: 'Link kopiert',
    the_result_is_stored: 'Das Ergebnis ist gespeichert',
    nothing_to_save: 'Keine Ergebnisse vorhanden!',
    was_changed: 'Hat sich verändert'
  },
  card: {
    card_without_article: 'Ohne Artikelnummer fortfahren'
  },
  form: {
    email: 'E-Mail',
    password: 'Passwort',
    email_address: 'E-Mail-Adresse',
    please_input_email: 'Bitte E-Mail-Adresse eingeben',
    please_input_password: 'Bitte Passwort eingeben',
    register_text: 'Sie haben noch keinen Account?. Erstellen Sie jetzt einen im Shop!',
    register_text_1: 'Sie haben noch keinen Account?',
    register_text_2: 'Erstellen Sie jetzt einen im',
    shop: 'Shop!',
    forgot_password: 'Haben Sie Ihr Password vergessen?'
  },
  modal: {
    result_name: 'Speichern unter:',
    input_result_name: 'Bitte geben Sie einen Namen ein'
  },
  table: {
    article_number: 'Artikelnummer',
    tensile_capacity: 'Zugtragfähigkeit (in N)',
    shear_load_capacity: 'Abschertragfähigkeit (in N)',
    combined_load_capacity: 'Kombinierte Tragfähigkeit (in N)',
    Mindesteinschraubtiefe: 'Mindesteinschraubtiefe',
    ergebnisse: 'Ergebnisse*',
    no_data: 'Keine Ergebnisse. Bitte wählen Sie andere Parameter.'
  },
  text: {
    calculated_on_the_basis: '*Berechnet auf Grundlage der ETA 12/0481'
  },
  alert_article: {
    66: 'Benutzer nicht gefunden',
    1: 'Benutzer nicht gefunden oder falsche Zugangsdaten',
    18: 'Benutzer nicht aktiviert',
    title: 'Die eingegebene Artikelnummer konnte nicht gefunden werden.',
    message: 'Entweder existiert sie nicht oder sie gehört zu einem Produkt, dass keine gültige CE-Zertifizierung besitzt. Sie können auch ohne Artikelnummer fortfahren und sich entsprechend Ihrer Anforderungen passende Muffen anzeigen lassen.'
  },
  options: {
    _1: '1',
    _2: '2',
    _3: '3',
    Vollholz_Nadel: 'Nadelvollholz',
    Vollholz_Laub: 'Laubvollholz',
    Brettschichtholz: 'Brettschichtholz',
    Brettsperrholz_CLT_Nadel: 'Brettsperrholz, Nadelholz, CLT',
    Brettsperrholz_CLT_Laub: 'Brettsperrholz, Laubholz, CLT',
    Funierschichtholz_LVL: 'Funierschichtholz, LVL',
    'Sperrholz_Typ EN 636': 'Sperrholz, Typ EN 636',
    'OSB/2': 'OSB/2',
    'OSB/3': 'OSB/3',
    'OSB/4': 'OSB/4',
    'Spanplatte_Typ P4': 'Spanplatte, Typ P4',
    'Spanplatte_Typ P5': 'Spanplatte, Typ P5',
    'Spanplatte_Typ P6': 'Spanplatte, Typ P6',
    'Spanplatte_Typ P7': 'Spanplatte, Typ P7',
    Holzfaserplatte_Hart: 'Holzfaserplatte, hart',
    Holzfaserplatte_mittelhart: 'Holzfaserplatte, mittelhart',
    Holzfaserplatte_MDF: 'Holzfaserplatte, MDF',
    'Zementgebundene Spanplatten': 'Zementgebundene Spanplatten',
    'ständige Einwirkung': 'Ständige Einwirkung',
    'lange Einwirkung': 'Lange Einwirkung',
    'mittlere Einwirkung': 'Mittlere Einwirkung',
    'kurze Einwirkung': 'Kurze Einwirkung',
    'sehr kurze Einwirkung': 'Sehr kurze Einwirkung',
    Holz: 'Holz',
    Blech: 'Blech',
    Seilöse: 'Seilöse',
    Seitenfläche: 'Seitenfläche',
    Schmalfläche: 'Schmalfläche'
  },
  inputs_error: {
    target_load_capacity: 'Der Wert muss zw. 100 und 40000 liegen',
    deviation: 'Der Wert muss zw. 10 und 100 liegen',
    article_number: 'Artikelnummer existiert nicht oder ist nicht CE-zertifiziert'
  },
  modal_text: {
    materials_text_1: 'Die Holzart in die die Muffe eingeschraubt wird.',

    classes_text_1: 'Die Umgebung in der sich die Konstruktion befindet:',

    load_times_text_1: 'Zeitspanne in der die Last auf die Konstruktion einwirkt.',

    mounting_angle_alpha_1_text_1: 'Die maximale Haltekraft liefert ein Einschrauben quer zur Faserrichtung (entspricht Winkel α = 90°). Längs bzw. mit der Faserrichtung ist die Haltekraft am geringsten (entspricht Winkel α = 0°). Die Winkelangabe erfolgt in Bezug zum Faserverlauf.',

    carrier_material_text_1: 'Bauteil das mithilfe einer Schraube an der Muffe befestigt wird.',

    installation_location_text_1: 'Seitenfläche = Größte Fläche am Bauteil (z. B. Tischoberfläche).',
    installation_location_text_2: 'Schmalfläche = Dünnste Fläche (z. B. Tischkante).',

    thick_component_2_wood_text_1: 'Dicke des anzuschraubenden Holzbauteils, Angabe erfolgt in mm.',

    sheet_thickness_text_1: 'Dicke des anzuschraubenden Blechbauteils, Angabe erfolgt in mm.',

    screw_strength_text_1: 'Schraubenfestigkeit der vorgesehenen Schraube.',

    angle_beta_text_1: 'Beta ist der Winkel zwischen Bolzenachse und Wirkrichtung der Kraft in Abhängigkeit von der Faserrichtung.',
    angle_beta_text_2: 'Wird nur die Abschertragfähigkeit betrachtet, so wirkt die Kraft immer in einem 90° Winkel zur Muffenachse.',
    angle_beta_text_3: 'Wirkt die Kraft in einem Winkel von 0° – 90° zwischen Bolzenachse und Faserrichtung, so ist es eine „kombinierte Last“.',

    combined_capacity_text_1: 'Winkel zwischen einwirkender Kraft und Bolzenachse. Die Faserrichtung bleibt hierbei unbeachtet.',

    target_load_capacity_text_1: 'Die erforderliche Tragfähigkeit der Muffe in Newton.',

    deviation_text_1: 'Die maximal zulässige Abweichung der erforderlichen Tragfähigkeit in Prozent.',

    article_number_text_1: 'Geben Sie eine RAMPA® Artikelnummer an (7-stellig), welche eine gültige CE-Zertifizierung besitzt.'
  },
  modal_list_title: {
    classes_list_title_1: 'Nutzungsklasse 1: Verwendung im Innenbereich',
    classes_list_title_2: 'Nutzungsklasse 2: Innenräume von Nutzbauten',
    classes_list_title_3: 'Nutzungsklasse 3: Bauteile im Freien mit konstruktivem Holzschutz',

    load_times_list_title_1: 'ständige Einwirkung',
    load_times_list_title_2: 'lange Einwirkung',
    load_times_list_title_3: 'mittlere Einwirkung',
    load_times_list_title_4: 'kurze Einwirkung',
    load_times_list_title_5: 'sehr kurze Einwirkung',

    shearing_capacity_list_title_1: 'Zu verbindende Materialkombination',
  },
  modal_list_text: {
    classes_list_1_text_1: 'keine Bewitterung',
    classes_list_1_text_2: 'z.B. Deckenkonstruktionen, Saalbestuhlungen, Ladenbau',
    classes_list_1_text_3: 'rel. Luftfeuchte ≤ 65 %',
    classes_list_1_text_4: 'rel. Holzfeuchte ≤ 12 %',

    classes_list_2_text_1: 'keine direkte Bewitterung',
    classes_list_2_text_2: 'z.B. Carports, Lagerhallen, überdachte Konstruktionen im Freien',
    classes_list_2_text_3: 'rel. Luftfeuchte ≤ 85 %',
    classes_list_2_text_4: 'rel. Holzfeuchte ≤ 20 %',

    classes_list_3_text_1: 'direkte Bewitterung',
    classes_list_3_text_2: 'die Verbindungsstellen sind konstruktiv geschützt',
    classes_list_3_text_3: 'z.B. Holzspielgerät, Freilichtbühnen',
    classes_list_3_text_4: 'rel. Luftfeuchte > 85 %',
    classes_list_3_text_5: 'rel. Holzfeuchte > 20 %',

    load_times_list_1_text_1: 'z.B. Eigenlasten, permanente Belastungen',

    load_times_list_2_text_1: 'e.g. storage weight, interior design',

    load_times_list_3_text_1: 'z.B. Nutzlasten, Schneelasten (Geländehöhe ab 1000 m über NN)',

    load_times_list_4_text_1: 'Schneelasten (Geländehöhe bis 1000 m über NN)',

    load_times_list_5_text_1: 'z.B. außergewöhnliche Lasten, Anpralllasten, Erdbeben',

    connection_type_list_1_text_1: 'Holz-Holz',
    connection_type_list_1_text_2: 'Holz-Blech',
    connection_type_list_1_text_3: 'Holz-Seilöse'
  },
  footer: {
    imprint: 'Impressum',
    privacy: 'Datenschutz'
  }
}
