import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { CalculatorLinks } from './styled'

const items = [
  {
    title: 'tensile_capacity',
    current: 0,
    disabled: false
  },
  {
    title: 'shearing_capacity',
    current: 1,
    disabled: false
  },
  {
    title: 'combined_capacity',
    current: 2,
    disabled: false
  }
]

const CalculatorTypes = props => {
  const {
    calculator: { tab },
    changeActiveTab
  } = props
  const { t } = useTranslation()
  const TabsRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, TabsRef.current.offsetTop)
  }, [tab])

  return (
    <CalculatorLinks>
      <input ref={TabsRef} type='hidden' />
      {items.map(i =>
        <CalculatorLinks.Item
          onClick={() => { !i.disabled && changeActiveTab({ tab: i.current }) }}
          key={i.title}
          active={i.current === tab}
        >
          {t(`title:${i.title}`)}
        </CalculatorLinks.Item>
      )}
    </CalculatorLinks>
  )
}

export default CalculatorTypes
