import styled from 'styled-components'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const CalcMethod = styled.div`
  text-align: center;
`

CalcMethod.Link = styled.a`
  display: inline-block;
  font-size: 1.8rem;
  line-height: 3.2rem;
  font-weight: 600;
  color: ${colors.blue};
  margin: 0 auto;
  text-decoration: underline;
`

export {
  CalcMethod
}
