import * as t from '../types'
import { api } from '../../services'
import { isFetching } from './helpers'

const getUserHistoryRecord = () => dispatch => {
  dispatch(isFetching(t.GET_HISTORY_USER_RECORD_REQUEST, true))

  return api.result.getUserHistoryRecord()
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: t.GET_HISTORY_USER_RECORD_SUCCESS,
          payload: data.records
        })
        dispatch(isFetching(t.GET_HISTORY_USER_RECORD_REQUEST, false))
      } else {
        dispatch(isFetching(t.GET_HISTORY_USER_RECORD_REQUEST, false))
      }
    })
}

const saveHistoryRecord = data => dispatch => {
  dispatch(isFetching(t.SAVE_HISTORY_RECORD_REQUEST, false))

  return api.result.saveHistoryRecord(data)
    .then(({ data }) => {
      if (data.status) {
        dispatch(isFetching(t.SAVE_HISTORY_RECORD_SUCCESS, false))
      } else {
        dispatch(isFetching(t.SAVE_HISTORY_RECORD_SUCCESS, false))
      }
    })
}

const removeHistoryRecord = body => dispatch => {
  dispatch(isFetching(t.REMOVE_HISTORY_RECORD_REQUEST, true))

  return api.result.removeHistoryRecord(body)
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: t.REMOVE_HISTORY_RECORD_SUCCESS,
          payload: body
        })
        dispatch(isFetching(t.REMOVE_HISTORY_RECORD_REQUEST, false))
      } else {
        dispatch(isFetching(t.REMOVE_HISTORY_RECORD_REQUEST, false))
      }
    })
}

export {
  getUserHistoryRecord,
  saveHistoryRecord,
  removeHistoryRecord
}
