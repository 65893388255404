import React from 'react'

import { ContentHeader, ContentHeaderActions, Heading } from '../../components'
import { SavedResultList } from './internal'
import { Result } from './styled'

const SavedResult = () => {
  return (
    <Result>
      <ContentHeader style={{ padding: '2.4rem 0' }}>
        <ContentHeaderActions actions={['back_to_calculator']} />
      </ContentHeader>

      <Result.Content>
        <Result.Container>
          <Result.Center>
            <Heading level={2}>saved_results</Heading>

            <SavedResultList style={{ marginTop: '5.6rem', marginBottom: '5rem' }} />
          </Result.Center>
        </Result.Container>
      </Result.Content>
    </Result>
  )
}

export default SavedResult
