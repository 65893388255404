const appSelects = [
  {
    title: 'tensile_capacity',
    inputs: [
      {
        title: 'materials',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'materials_text_1'
            }
          ]
        }
      },
      {
        title: 'classes',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'classes_text_1'
            }
          ],
          calculation_examples: [
            {
              title: 'classes_list_title_1',
              list: [
                {
                  text: 'classes_list_1_text_1'
                },
                {
                  text: 'classes_list_1_text_2'
                },
                {
                  text: 'classes_list_1_text_3'
                },
                {
                  text: 'classes_list_1_text_4'
                }
              ]
            },
            {
              title: 'classes_list_title_2',
              list: [
                {
                  text: 'classes_list_2_text_1'
                },
                {
                  text: 'classes_list_2_text_2'
                },
                {
                  text: 'classes_list_2_text_3'
                },
                {
                  text: 'classes_list_2_text_4'
                }
              ]
            },
            {
              title: 'classes_list_title_3',
              list: [
                {
                  text: 'classes_list_3_text_1'
                },
                {
                  text: 'classes_list_3_text_2'
                },
                {
                  text: 'classes_list_3_text_3'
                },
                {
                  text: 'classes_list_3_text_4'
                },
                {
                  text: 'classes_list_3_text_5'
                }
              ]
            }
          ]
        }
      },
      {
        title: 'load_times',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'load_times_text_1'
            }
          ],
          calculation_examples: [
            {
              title: 'load_times_list_title_1',
              list: [
                {
                  text: 'load_times_list_1_text_1'
                }
              ]
            },
            {
              title: 'load_times_list_title_2',
              list: [
                {
                  text: 'load_times_list_2_text_1'
                }
              ]
            },
            {
              title: 'load_times_list_title_3',
              list: [
                {
                  text: 'load_times_list_3_text_1'
                }
              ]
            },
            {
              title: 'load_times_list_title_4',
              list: [
                {
                  text: 'load_times_list_4_text_1'
                }
              ]
            },
            {
              title: 'load_times_list_title_5',
              list: [
                {
                  text: 'load_times_list_5_text_1'
                }
              ]
            }
          ]
        }
      },
      {
        title: 'mounting_angle_alpha_1',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'mounting_angle_alpha_1_text_1'
            }
          ]
        }
      }
    ]
  },
  {
    title: 'shearing_capacity',
    inputs: [
      {
        title: 'connection_type',
        withIcon: true,
        description: {
          texts: [],
          calculation_examples: [
            {
              title: 'shearing_capacity_list_title_1',
              list: [
                {
                  text: 'connection_type_list_1_text_1'
                },
                {
                  text: 'connection_type_list_1_text_2'
                },
                {
                  text: 'connection_type_list_1_text_3'
                }
              ]
            }
          ]
        }
      },
      {
        title: 'carrier_material',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'carrier_material_text_1'
            }
          ]
        }
      },
      {
        title: 'installation_location',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'installation_location_text_1'
            },
            {
              text: 'installation_location_text_2'
            }
          ]
        }
      },
      {
        title: 'thick_component_2_wood',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'thick_component_2_wood_text_1'
            }
          ]
        }
      },
      {
        title: 'sheet_thickness',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'sheet_thickness_text_1'
            }
          ]
        }
      },
      {
        title: 'screw_strength',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'screw_strength_text_1'
            }
          ]
        }
      },
      {
        title: 'angle_beta',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'angle_beta_text_1'
            },
            {
              text: 'angle_beta_text_2'
            },
            {
              text: 'angle_beta_text_3'
            }
          ]
        }
      }
    ]
  },
  {
    title: 'combined_capacity',
    inputs: [
      {
        title: 'power_attack_angle_alpha_2',
        withIcon: true,
        description: {
          texts: [
            {
              text: 'combined_capacity_text_1'
            }
          ]
        }
      }
    ]
  }
]

const appSelectsValidationObj = {
  classes: {
    materials: {
      'Vollholz_Nadel': ['_1', '_2', '_3'],
      'Vollholz_Laub': ['_1', '_2', '_3'],
      'Brettschichtholz': ['_1', '_2', '_3'],
      'Brettsperrholz_CLT_Nadel': ['_1', '_2'],
      'Brettsperrholz_CLT_Laub': ['_1', '_2'],
      'Funierschichtholz_LVL': ['_1', '_2', '_3'],
      'Sperrholz_Typ EN 636': ['_1', '_2', '_3'],
      'OSB/2': ['_1'],
      'OSB/3': ['_1', '_2'],
      'OSB/4': ['_1', '_2'],
      'Spanplatte_Typ P4': ['_1'],
      'Spanplatte_Typ P5': ['_1', '_2'],
      'Spanplatte_Typ P6': ['_1'],
      'Spanplatte_Typ P7': ['_1', '_2']
    }
  },
  carrier_material: {
    classes: {
      _2: {
        load_times: {
          'ständige Einwirkung': ['Vollholz_Nadel', 'Vollholz_Laub', 'Brettschichtholz', 'Funierschichtholz_LVL',
            'Sperrholz_Typ EN 636', 'OSB/3', 'OSB/4', 'Spanplatte_Typ P5', 'Spanplatte_Typ P7',
            'Holzfaserplatte_Hart',
            'Zementgebundene Spanplatten', 'Brettsperrholz_CLT_Nadel', 'Brettsperrholz_CLT_Laub'],
          'lange Einwirkung': ['Vollholz_Nadel', 'Vollholz_Laub', 'Brettschichtholz', 'Funierschichtholz_LVL',
            'Sperrholz_Typ EN 636', 'OSB/3', 'OSB/4', 'Spanplatte_Typ P5', 'Spanplatte_Typ P7',
            'Holzfaserplatte_Hart',
            'Zementgebundene Spanplatten', 'Brettsperrholz_CLT_Nadel', 'Brettsperrholz_CLT_Laub'],
          'mittlere Einwirkung': ['Vollholz_Nadel', 'Vollholz_Laub', 'Brettschichtholz', 'Funierschichtholz_LVL',
            'Sperrholz_Typ EN 636', 'OSB/3', 'OSB/4', 'Spanplatte_Typ P5', 'Spanplatte_Typ P7',
            'Holzfaserplatte_Hart',
            'Zementgebundene Spanplatten', 'Brettsperrholz_CLT_Nadel', 'Brettsperrholz_CLT_Laub'],
          'kurze Einwirkung': ['Vollholz_Nadel', 'Vollholz_Laub', 'Brettschichtholz', 'Funierschichtholz_LVL',
            'Sperrholz_Typ EN 636', 'OSB/3', 'OSB/4', 'Spanplatte_Typ P5', 'Spanplatte_Typ P7',
            'Holzfaserplatte_Hart', 'Zementgebundene Spanplatten', 'Brettsperrholz_CLT_Nadel', 'Brettsperrholz_CLT_Laub', 'Holzfaserplatte_mittelhart',
            'Holzfaserplatte_MDF'],
          'sehr kurze Einwirkung': ['Vollholz_Nadel', 'Vollholz_Laub', 'Brettschichtholz', 'Funierschichtholz_LVL',
            'Sperrholz_Typ EN 636', 'OSB/3', 'OSB/4', 'Spanplatte_Typ P5', 'Spanplatte_Typ P7',
            'Holzfaserplatte_Hart', 'Zementgebundene Spanplatten', 'Brettsperrholz_CLT_Nadel', 'Brettsperrholz_CLT_Laub', 'Holzfaserplatte_mittelhart',
            'Holzfaserplatte_MDF']
        }
      },
      _3: ['Vollholz_Nadel', 'Vollholz_Laub', 'Brettschichtholz', 'Funierschichtholz_LVL',
        'Sperrholz_Typ EN 636']
    }
  },
  angle_beta: {
    connection_type: {
      'Blech': [90],
      'Seilöse': [90]
    }
  }
}

const unavailableSelects = {
  Holz: ['sheet_thickness'],
  Blech: ['carrier_material', 'thick_component_2_wood'],
  Seilöse: ['sheet_thickness', 'carrier_material', 'thick_component_2_wood']
}

const appInputs = [
  {
    label: 'target_load_capacity',
    with_article_number: false,
    value: '',
    withIcon: true,
    format: '#####',
    description: {
      texts: [
        {
          text: 'target_load_capacity_text_1'
        }
      ]
    }
  },
  {
    label: 'deviation',
    with_article_number: false,
    withIcon: true,
    value: 10,
    format: '+/-###%',
    description: {
      texts: [
        {
          text: 'deviation_text_1'
        }
      ]
    }
  },
  {
    label: 'article_number',
    value: '',
    with_article_number: true,
    withIcon: true,
    format: '### ### #',
    description: {
      texts: [
        {
          text: 'article_number_text_1'
        }
      ]
    }
  }
]

export default {
  appSelects,
  appInputs,
  appSelectsValidationObj,
  unavailableSelects
}
