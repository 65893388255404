import styled from 'styled-components'
import { Spin, Icon } from 'antd'

const PreloaderSpin = styled(Spin)`
  && {
    width: 100%;
    padding-top: 15rem;
  }
`

const PreloaderIcon = styled(Icon)`
  && {
    font-size: 5rem;
  }
`

export {
  PreloaderSpin,
  PreloaderIcon
}
