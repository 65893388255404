import { connect } from 'react-redux'
import actions from '../../../../store/actions'
import SavedResultList from './SavedResultList'

const mapStateToProps = state => ({
  result: state.result
})

const mapDispatchToProps = dispatch => ({
  getUserHistoryRecord: () => dispatch(actions.result.getUserHistoryRecord()),
  removeHistoryRecord: data => dispatch(actions.result.removeHistoryRecord(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedResultList)
