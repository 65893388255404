import React from 'react'
import { useTranslation } from 'react-i18next'

import history from '../../../../history'
import {
  getFilteredSelects,
  validateData,
  validateInputs,
  targetLoadCapacityRegexp,
  deviationRegexp
} from '../../../../utils'
import { controls } from '../../../../constants'
import { Icon } from 'antd'
import { Heading, InputGroup, Button, InputsWrapper } from '../../../../components'
import { InputsBlock } from './styled'

const { appInputs, appSelects, unavailableSelects } = controls

const Inputs = props => {
  const {
    dashboard: { calc_method, method, activeInputs },
    options: { options },
    calculator: { isFetching },
    inputs: { app_inputs, app_selects },
    getResultData,
    changeInput,
    changeSelect,
    setErrorInput
  } = props
  const { t } = useTranslation()

  const handleSelectChange = (value, field) => {
    changeSelect({ value: value, field: field })
  }

  const handleInputChange = ({ value }, field) => {
    changeInput({ value: value, field: field })
  }

  const handleButtonClick = () => {
    const data = {
      ...getFilteredSelects(appSelects, app_selects, method),
      ...app_inputs
    }
    if (activeInputs.length === 1) {
      getResultData(data, validateData(data, data.connection_type), method)
        .then((response) => {
          if (response[1].status) {
            history.push('/calculator')
          }
        })
    } else {
      if (validateInputs(app_inputs.target_load_capacity, targetLoadCapacityRegexp) && validateInputs(app_inputs.deviation, deviationRegexp)) {
        getResultData(data, validateData(data, data.connection_type), method)
          .then(() => {
            history.push('/calculator')
          })
      } else {
        setErrorInput({ input: 'target_load_capacity', status: !validateInputs(app_inputs.target_load_capacity, targetLoadCapacityRegexp) })
        setErrorInput({ input: 'deviation', status: !validateInputs(app_inputs.deviation, deviationRegexp) })
      }
    }
  }

  const controlSelect = app_selects.connection_type

  return (
    <InputsBlock>
      <Heading level={2}>{calc_method[method]}</Heading>

      <InputsWrapper style={{ margin: '3.2rem 0' }}>
        {appInputs.filter(i => activeInputs.includes(i.label)).map(i =>
          <InputGroup
            type='number'
            withIcon={i.withIcon}
            key={i.label}
            label={i.label}
            placeholder={i.label}
            description={i.description}
            format={i.format && i.format}
            onValueChange={values => handleInputChange(values, i.label)}
          />
        )}
      </InputsWrapper>

      <InputsBlock.Content>
        {controlSelect &&
          <>
            {appSelects.filter((i, idx) => idx <= method).map(item =>
              <div key={item.title}>
                <InputsWrapper column title={t(`title:${item.title}`)}>
                  {item.inputs.filter(i => !unavailableSelects[controlSelect].includes(i.title)).map(i =>
                    <InputGroup
                      type='select'
                      color='white'
                      key={i.title}
                      withIcon={i.withIcon}
                      label={i.title}
                      options={options[`${i.title}`]}
                      description={i.description}
                      onChange={value => handleSelectChange(value, i.title)}
                    />
                  )}
                </InputsWrapper>
              </div>
            )}
          </>
        }
      </InputsBlock.Content>

      <Button
        large
        style={{ marginTop: '3.2rem', maxWidth: '33.6rem', padding: '1.7rem 0', fontSize: '2.2rem' }}
        onClick={handleButtonClick}
      >
        {isFetching
          ? <Icon type='loading' />
          : 'calculate'}
      </Button>
    </InputsBlock>
  )
}

export default Inputs
