import { connect } from 'react-redux'
import actions from '../../../../store/actions'
import CalculationMethod from './CalculatorContent'

const mapStateTiProps = state => ({
  calculator: state.calculator,
  inputs: state.inputs,
  options: state.options
})

const mapDispatchToProps = dispatch => ({
  resetCalculator: data => dispatch(actions.calculator.resetCalculator(data))
})

export default connect(
  mapStateTiProps,
  mapDispatchToProps
)(CalculationMethod)
