import { connect } from 'react-redux'
import SavedResult from './SavedResult'

const mapStateToProps = state => ({
  result: state.result
})

export default connect(
  mapStateToProps,
  null
)(SavedResult)
