import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import useForm from 'rc-form-hooks'
import history from '../../history'

import { api, i18n } from '../../services'
import { Form, Input, Icon } from 'antd'
import { Heading, ContentHeader, Button, Alert, Preloader } from '../../components'
import { Login } from './styled'

const defaultState = {
  fetching: false,
  loading: false,
  loginError: ''
}

const LoginPage = () => {
  const { t } = useTranslation()
  const { getFieldDecorator, validateFields, values } = useForm()
  const [state, setState] = useState({ ...defaultState })

  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()

  const getRegisterLinkByLanguage = language => {
    switch (language) {
      case 'en':
        return 'https://www.rampa.com/de/en/authentifizierung'
      case 'de': {
        return 'https://www.rampa.com/de/de/authentifizierung'
      }
      default:
        return 'https://www.rampa.com/de/en/authentifizierung'
    }
  }

  const getForgotPasswordLink = language => {
    switch (language) {
      case 'en': return 'https://www.rampa.com/de/en/passwordlost/recover'
      case 'de': return 'https://www.rampa.com/de/de/passwordlost/recover'
      default: return 'https://www.rampa.com/de/en/passwordlost/recover'
    }
  }

  useEffect(() => {
    setState({
      ...state,
      loading: true
    })

    if (query.get('api_key')) {
      window.localStorage.setItem('authToken', query.get('api_key'))
      history.push('/')
    } else {
      setState({ ...defaultState })
    }
  }, [])

  const handleSubmit = e => {
    setState({
      ...state,
      fetching: true
    })
    e.preventDefault()
    validateFields()
      .then(() => {
        api.user.userLogin(values)
          .then(({ data }) => {
            if (data.status) {
              window.localStorage.setItem('authToken', data.api_token)
              history.push('/')
            } else {
              throw new Error(data.errors[0].code)
            }
          })
          .catch(error => {
            setState({
              ...state,
              fetching: false,
              loginError: error.message
            })
          })
      })
      .catch(error => {
        setState({
          ...state,
          fetching: false
        })
      })
  }

  const { fetching, loginError, loading } = state

  return (
    <Login>
      <ContentHeader style={{ padding: '2rem 0' }}>
        <div />
        <Heading level={2}>login_to_the_calculator_tool</Heading>
        <div />
      </ContentHeader>

      {loading
        ? <Preloader />
        : <Login.Content>
          <Alert
            style={{ maxWidth: '36rem', margin: '0 auto' }}
            title={loginError}
            visible={!!loginError}
          />

          <Login.Form style={{ marginTop: '3.2rem', marginBottom: '4rem' }}>
            <Form onSubmit={handleSubmit}>
              <Form.Item label={t('form:email')}>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: t('form:please_input_email') }]
                })(
                  <Input placeholder={t('form:email_address')} />
                )}
              </Form.Item>

              <Form.Item label={t('form:password')}>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: t('form:please_input_password') }]
                })(
                  <Input type='password' placeholder={t('form:password')} />
                )}
              </Form.Item>

              <Button style={{ marginTop: '2.4rem' }} htmlType='submit'>
                {fetching
                  ? <Icon type='loading' />
                  : 'log_in'}
              </Button>
            </Form>
          </Login.Form>
          <Login.FormText>
            {t('form:register_text_1')}
          </Login.FormText>
          <Login.FormText>
            {t('form:register_text_2')}
            <a
              style={{ paddingLeft: '.3rem', textDecoration: 'underline' }}
              href={getRegisterLinkByLanguage(i18n.language.split('-')[0])}
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('form:shop')}
            </a><br />
            <a
              style={{ display: 'inline-block', marginTop: '2.4rem' }}
              href={getForgotPasswordLink(i18n.language.split('-')[0])}
              target='_blank'
              rel='noopener noreferrer'
            >{t('form:forgot_password')}
            </a>
          </Login.FormText>
        </Login.Content>}
    </Login>
  )
}

export default LoginPage
