import styled from 'styled-components'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Login = styled.div`
  h2 {
    color: ${colors.white} !important;  
  }
`
Login.Content = styled.div`
  padding-top: 4rem;
`
Login.Form = styled.div`
  max-width: 36rem;
  background-color: ${colors.whisper};
  border-radius: .6rem;
  padding: 4rem 4.3rem;
  margin: 0 auto;
`

Login.FormText = styled.p`
  font-size: 1.4rem;
  text-align: center;
  line-height: 2rem;
  color: map-get($colors, main);
  margin-bottom: 0;
`

export {
  Login
}
