import * as t from '../types'
import { api } from '../../services'
import { isFetching, validateField } from './helpers'
import {
  targetLoadCapacityRegexp,
  deviationRegexp,
  validateInputs
} from '../../utils'

const changeDirectlyStep = data => ({
  type: t.CHANGE_DIRECTLY_STEP,
  payload: data
})

const changeStepWithParams = data => ({
  type: t.CHANGE_STEP_WITH_PARAMS,
  payload: data
})

const setArticleNumber = (body, params) => dispatch => {
  dispatch(isFetching(t.CHECK_ARTICLE_NUMBER_REQUEST, true))

  return api.article.checkArticleNumber(params)
    .then(({ data }) => {
      if (data.is_exist) {
        dispatch({
          type: t.CHECK_ARTICLE_NUMBER_SUCCESS,
          payload: body
        })
        dispatch({
          type: t.HANDLE_INPUT_CHANGE,
          payload: { value: body.article_number, field: 'article_number' }
        })
      } else {
        dispatch({
          type: t.CHECK_ARTICLE_NUMBER_FAILURE,
          payload: { error: true, field: 'article_number' }
        })
      }
      dispatch(isFetching(t.CHECK_ARTICLE_NUMBER_REQUEST, false))
    })
    // eslint-disable-next-line handle-callback-err
    .catch(error => {
      dispatch(isFetching(t.CHECK_ARTICLE_NUMBER_REQUEST, false))
    })
}

const getResultData = (data, validateData, tab) => dispatch => {
  dispatch(isFetching(t.GET_RESULT_DATA_REQUEST, true))

  dispatch({
    type: t.CHOOSE_ACTIVE_TAB,
    payload: tab
  })

  if (data.target_load_capacity && data.deviation) {
    dispatch(validateField(t.CHECK_INPUTS_FAILURE, 'target_load_capacity', data.target_load_capacity, targetLoadCapacityRegexp))
    dispatch(validateField(t.CHECK_INPUTS_FAILURE, 'deviation', data.deviation, deviationRegexp))
  }

  const getCalculateMethodByActiveCalculator = calculator => {
    switch (calculator) {
      case 0:
        return api.article.calculateBasicForm(validateData)
      case 1:
        return api.article.calculateAdvancedForm(validateData)
      case 2:
        return api.article.calculateCombinedForm(validateData)
      default:
        return api.article.calculateBasicForm(validateData)
    }
  }

  const getSearchMethodByActiveCalculator = calculator => {
    switch (calculator) {
      case 0:
        return api.article.searchProducts(validateData)
      case 1:
        return api.article.searchProductsAdvanced(validateData)
      case 2:
        return api.article.searchProductsCombined(validateData)
      default:
        return api.article.searchProducts(validateData)
    }
  }

  return Promise.resolve()
    .then(() => {
      const promises = []

      if (validateInputs(data.target_load_capacity, targetLoadCapacityRegexp) && validateInputs(data.deviation, deviationRegexp)) {
        promises.push(getSearchMethodByActiveCalculator(tab))
      } else {
        promises.push(Promise.resolve({ data: { products: [] } }))
      }

      if (data.article_number) {
        const body = data
        return api.article.checkArticleNumber({ code: body.article_number })
          .then(({ data }) => {
            if (data.is_exist) {
              const arr = [
                getCalculateMethodByActiveCalculator(tab),
                api.article.checkProducts(body)
              ]
              promises.push(...arr)
              return promises
            } else {
              dispatch({
                type: t.CHECK_ARTICLE_NUMBER_FAILURE,
                payload: { error: true, field: 'article_number' }
              })
              const arr = [
                Promise.resolve({ data: { calculation_result: {} } }),
                Promise.resolve({ data: { product: [] } })
              ]
              promises.push(...arr)
              return promises
            }
          })
      } else {
        const arr = [
          Promise.resolve({ data: { calculation_result: {} } }),
          Promise.resolve({ data: { product: [] } })
        ]
        promises.push(...arr)
        return promises
      }
    })
    .then(promises => {
      return Promise.all(promises)
        .then(result => result.map(i => i.data))
        .then(result => {
          dispatch({
            type: t.GET_RESULT_DATA_SUCCESS,
            payload: result
          })
          dispatch(isFetching(t.GET_RESULT_DATA_REQUEST, false))
          return result
        })
        .catch(error => {
          dispatch(isFetching(t.GET_RESULT_DATA_REQUEST, false))
          dispatch({
            type: t.GET_RESULT_DATA_FAILURE,
            payload: error
          })
        })
    })
}

export {
  changeDirectlyStep,
  changeStepWithParams,
  setArticleNumber,
  getResultData
}
