import { controls } from '../../constants'
import * as t from '../types'

const { appSelects, appInputs } = controls

const app_selects = appSelects
  .reduce((acc, i) => {
    const inputsObj = i.inputs
      .reduce((accum, el) => ({ ...accum, [el.title]: '' }), {})
    return { ...acc, ...inputsObj }
  }, {})

const app_inputs = appInputs
  .reduce((acc, i) => ({ ...acc, [i.label]: i.value }), {})

const initialState = {
  app_selects: app_selects,
  app_inputs: app_inputs,
  errors: {
    target_load_capacity: false,
    deviation: false,
    article_number: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.SET_RESULT_INPUTS:
      return {
        ...state,
        app_selects: {
          ...state.app_selects,
          ...action.payload
        }
      }
    case t.SET_ERROR_INPUT:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.input]: action.payload.status
        }
      }
    case t.HANDLE_INPUT_CHANGE:
      return {
        ...state,
        app_inputs: {
          ...state.app_inputs,
          [action.payload.field]: action.payload.value
        },
        errors: {
          ...state.errors,
          [action.payload.field]: false
        }
      }
    case t.HANDLE_SELECT_CHANGE:
      return {
        ...state,
        app_selects: {
          ...state.app_selects,
          [action.payload.field]: action.payload.value
        }
      }
    case t.CHECK_ARTICLE_NUMBER_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error
        }
      }
    case t.CHECK_INPUTS_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error
        }
      }
    case t.RESET_CALCULATOR: {
      return {
        app_selects: action.payload.defaultSelects,
        app_inputs: action.payload.defaultInputs,
        errors: initialState.errors
      }
    }
    case t.BACK_TO_START: {
      return {
        app_selects: action.payload.defaultSelects,
        app_inputs: action.payload.defaultInputs,
        errors: initialState.errors
      }
    }
    case t.CHANGE_DIRECTLY_STEP:
      return {
        app_selects: action.payload.defaultSelects,
        app_inputs: action.payload.defaultInputs,
        errors: initialState.errors
      }
    default:
      return state
  }
}
