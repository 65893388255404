const colors = {
  main: '#333',
  black: '#000',
  white: '#fff',
  gray: '#E5E5E5',
  blue: '#243A8A',
  biscay: '#1C2F6E',
  chambray: '#3A4E95',
  chambray_second: '#344892',
  yellow: '#F3BF45',
  xanadu: '#7D7E7D',
  mine_shaft: '#2D2D2D',
  dove_gray: '#666',
  dusty_gray: '#9C9B9B',
  alabaster: '#F8F8F8',
  mystic: '#E9EBF3',
  burnt_sienna: '#EB5656',
  bay_of_many: '#253B8B',
  whisper: '#F0F1F7'
}

export default {
  colors
}
