import styled from 'styled-components'
import { rgba } from 'polished'
import ReactSVG from 'react-svg'
import { styleguide } from '../../constants'

const { colors } = styleguide

const HeaderBlock = styled.header`
  background-color: ${colors.white};
`

HeaderBlock.Container = styled.div`
  max-width: 116rem;
  margin: 0 auto;
  padding: 0 1rem;
`

HeaderBlock.Row = styled.div`
  display: flex;
  justify-content: space-between;
`

HeaderBlock.Merch = styled.div`
  padding: 1.6rem 0 1rem;
`

HeaderBlock.MerchLogo = styled(ReactSVG)`
  cursor: pointer;
`

HeaderBlock.Type = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 3rem;
`

HeaderBlock.Div = styled.div``

HeaderBlock.Link = styled.a`
  display: inline-block;
  font-size: 1.4rem;
    color: ${colors.black};
  font-weight: bold;
  line-height: 1.6rem;
  text-transform: uppercase;
  padding: 1.6rem 2.7rem;
  border-radius: 0 0 0.6rem 0.6rem;
  background-color: ${colors.yellow};
  box-shadow: 0 .4rem .4rem 0 ${rgba(colors.black, 0.25)};
`

export {
  HeaderBlock
}
