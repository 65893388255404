export default {
  general: {
    calculator_tool: 'Calculator Tool',
    online_shop: 'ONLINE SHOP'
  },
  buttons: {
    continue: 'Continue',
    calculate: 'CALCULATE',
    open_saved_results: 'Open Saved Results',
    save_results: 'Save Results',
    back_to_calculator: 'Back to Calculator',
    back_to_start: 'Back to start',
    reset_all: 'Reset All',
    print: 'Print',
    share: 'Share results',
    download: 'Download PDF',
    remove: 'Remove',
    log_in: 'LOG IN'
  },
  input_label: {
    article_number: 'Item number',
    target_load_capacity: 'Target load capacity',
    deviation: 'Divergence'
  },
  select_label: {
    article_number: 'Item number',
    target_load_capacity: 'Target load capacity N',
    deviation: 'Divergence',
    materials: 'Material',
    classes: 'Utility class',
    load_times: 'Load duration',
    mounting_angle_alpha_1: 'Assembling angle Alpha1',
    carrier_material: 'Carrier materials',
    connection_type: 'Connection type',
    installation_location: 'Installation place',
    thick_component_2_wood: 'Component thickness 2 Wood',
    sheet_thickness: 'Sheetmetal thickness',
    screw_strength: 'Screw strength',
    angle_beta: 'Angle Beta',
    power_attack_angle_alpha_2: 'Power attack angle Alpha2'
  },
  steps: {
    calculation_method: 'Calculation method',
    article_number: 'Item number',
    inputs: 'Inputs',
    result: 'Result'
  },
  title: {
    proceed_with_the_calculation: 'Would you like to proceed with the calculation for a specific item number?',
    calculation_method_title: 'What do you want to calculate?',
    tensile_capacity: 'Tensile capacity',
    shearing_capacity: 'Shearing capacity',
    combined_capacity: 'Combined capacity',
    particular_article: 'Do you want to calculate the Resistance for a Particular article?',
    saved_results: 'Saved Results',
    login_to_the_calculator_tool: 'Login to the ETA-Calculator',
    no_saved_results: 'No saved results.',
    do_you_want_to_delete: 'Do you want to delete?',
    yes: 'YES',
    no: 'NO',
    result_ETA: 'Results, calculated on the basis of the ETA 12/0481',
    directly_to_the_calculator_tool: 'Enter data directly',
    shearing_capacity_specific_item: 'Shearing capacity for a specific Item',
    suggested_sleeves: 'Inserts that fulfill your requirements',
    edge_distances_1: 'Edge distances',
    edge_distances_2: 'for the entered item number',
    mindesteinschraubtiefe: 'Minimum screw-in depth',
    do_you_want_to_calculate: 'Do you want to calculate',
    for_a_particular_article: 'for a Particular article?',
    no_results: 'Please adjust the parameters and start a new calculation to get results.'
  },
  notifications: {
    calculation_result_is_empty: 'Calculation result is empty',
    link_copied_to_clipboard: 'Link copied to clipboard',
    the_result_is_stored: 'The result is stored',
    nothing_to_save: 'Nothing to save',
    was_changed: 'was changed'
  },
  card: {
    card_without_article: 'Continue without an item number'
  },
  form: {
    email: 'Email',
    password: 'Password',
    email_address: 'Email address',
    please_input_email: 'Please, input your email address',
    please_input_password: 'Please, input your email password',
    register_text_1: 'You don\'t have an account?',
    register_text_2: 'Set one up in the',
    shop: 'Shop!',
    forgot_password: 'Forgot password?'
  },
  modal: {
    result_name: 'Result name:',
    input_result_name: 'Please, input result name'
  },
  table: {
    article_number: 'Article number',
    tensile_capacity: 'Target load capacity (in N)',
    shear_load_capacity: 'Shear load capacity (in N)',
    combined_load_capacity: 'Combined load capacity (in N)',
    Mindesteinschraubtiefe: 'Minimum screw-in depth',
    ergebnisse: 'Results*',
    no_data: 'No results. Please select other parameters.'
  },
  text: {
    calculated_on_the_basis: '*Calculated on the basis of ETA 12/0481'
  },
  alert_article: {
    66: 'User not found',
    1: 'User not found or incorrect credentials',
    18: 'User not active',
    title: 'The article number you entered could not be found.',
    message: 'It is either not existing or belongs to a product that does not have a CE-certification. You may proceed without an article number and the tool will display recommended products depending on your inputs.'
  },
  options: {
    _1: '1',
    _2: '2',
    _3: '3',
    Vollholz_Nadel: 'Hardwood',
    Vollholz_Laub: 'Softwood',
    Brettschichtholz: 'Glued laminated wood / glulam',
    Brettsperrholz_CLT_Nadel: 'Cross laminated wood, softwood, CLT',
    Brettsperrholz_CLT_Laub: 'Cross laminated timber, hardwood, CLT',
    Funierschichtholz_LVL: 'Laminated veneered timber, LVT',
    'Sperrholz_Typ EN 636': 'Plywood, type EN 636',
    'OSB/2': 'OSB boards/2',
    'OSB/3': 'OSB boards/3',
    'OSB/4': 'OSB boards/4',
    'Spanplatte_Typ P4': 'Chipboard, type P4',
    'Spanplatte_Typ P5': 'Chipboard, type P5',
    'Spanplatte_Typ P6': 'Chipboard, type P6',
    'Spanplatte_Typ P7': 'Chipboard, type P7',
    Holzfaserplatte_Hart: 'Fibreboard, hard',
    Holzfaserplatte_mittelhart: 'Fibreboard, medium-hard',
    Holzfaserplatte_MDF: 'Fibreboard, MDF',
    'Zementgebundene Spanplatten': 'Cement bonded particleboard',
    'ständige Einwirkung': 'Permanent load bearing',
    'lange Einwirkung': 'Continuous load bearing',
    'mittlere Einwirkung': 'Medium load bearing',
    'kurze Einwirkung': 'Short load bearing',
    'sehr kurze Einwirkung': 'Very short load bearing',
    Holz: 'Wood-wood',
    Blech: 'Wood-metal',
    Seilöse: 'Wood-rope grommet',
    Seitenfläche: 'Lateral surface',
    Schmalfläche: 'Narrow surface'
  },
  inputs_error: {
    target_load_capacity: 'The value must be between 100 and 40000',
    deviation: 'The value must be between 10 and 100',
    article_number: 'The item number does not exist or is not CE-certified'
  },
  modal_text: {
    materials_text_1: 'Type of wood.',

    classes_text_1: 'The construction environment is:',

    load_times_text_1: 'Construction load timespan.',

    mounting_angle_alpha_1_text_1: 'Maximum holding force is achieved by screwing in transverse to the wood fibre (equals an angle of α = 90°). Following the fibres lengthways gives the least holding force (equals an angle of α = 0°). The angle is described in relation to the orientation of wood fibres.',

    carrier_material_text_1: 'The element, that is attached to the insert by means of a screw.',

    installation_location_text_1: 'Where the material is connected to the other.',
    installation_location_text_2: '',

    thick_component_2_wood_text_1: 'Thickness of the material to be fixed, in millimetres.',

    sheet_thickness_text_1: 'Thickness of the metal part to be fixed, in millimetres.',

    screw_strength_text_1: 'Grade of the screw.',

    angle_beta_text_1: 'Beta angle is specified in relation to the bolt axis depending upon the direction of the impacting force.',
    angle_beta_text_2: 'Considering only the shearing capacity, means that the force attacks at an angle of 90° in relation to the angle of the insert.',
    angle_beta_text_3: 'A “combined load” is present, if the attacking force between bolt axis and fibre orientation is 0° – 90°.',

    combined_capacity_text_1: 'Angle between attacking force and bolt axis. The orientation of the fibres can be neglected.',

    target_load_capacity_text_1: 'The required load capacity of the insert in Newton.',

    deviation_text_1: 'The maximum allowed deviation from the required load capacity in percent.',

    article_number_text_1: 'You may use an item number of RAMPA® products (7 digits), that has a valid CE certification.'
  },
  modal_list_title: {
    classes_list_title_1: 'class 1: indoors',
    classes_list_title_2: 'class 2: functional building-indoors',
    classes_list_title_3: 'class 3: wooden material shielding exterior components',

    load_times_list_title_1: 'permanent load bearing',
    load_times_list_title_2: 'continuous load bearing',
    load_times_list_title_3: 'medium load bearing',
    load_times_list_title_4: 'short load bearing',
    load_times_list_title_5: 'very short load bearing',

    shearing_capacity_list_title_1: 'Which material is to be attached:'
  },
  modal_list_text: {
    classes_list_1_text_1: 'no environmental conditions',
    classes_list_1_text_2: 'e.g. canopies, hall seating, display casings',
    classes_list_1_text_3: 'el. humidity ≤ 65 %',
    classes_list_1_text_4: 'rel. wood humidity ≤ 12 %',

    classes_list_2_text_1: 'no direct environmental conditions',
    classes_list_2_text_2: 'e.g. carports, storage halls',
    classes_list_2_text_3: 'rel. humidity ≤ 85 %',
    classes_list_2_text_4: 'rel. wood humidity ≤ 20 %',

    classes_list_3_text_1: 'direct weathering',
    classes_list_3_text_2: 'the junctions are structurally protected',
    classes_list_3_text_3: 'e.g playground equipment, outdoor theatre',
    classes_list_3_text_4: 'rel. humidity > 85 %',
    classes_list_3_text_5: 'rel. wooden humidity > 20 %',

    load_times_list_1_text_1: 'e.g. total mass of construction',

    load_times_list_2_text_1: 'e.g. storage weight, interior design',

    load_times_list_3_text_1: 'loads sustaining normal usage usually lasting 2-24 hours e.g weight of snow [ground elevation over 1000 m above sea level]',

    load_times_list_4_text_1: 'e.g wind load, dynamic loads (e.g. gymnastic apparatus or weight of snow [ground elevation up to 1000 m above sea level])',

    load_times_list_5_text_1: 'extraordinary loads, loads caused by impact, e.g earthquakes',

    connection_type_list_1_text_1: 'wood-wood',
    connection_type_list_1_text_2: 'wood-metal',
    connection_type_list_1_text_3: 'wood-rope grommet'
  },
  footer: {
    imprint: 'Imprint',
    privacy: 'Privacy'
  }
}
