export default (instance) => {
  instance.interceptors.request.use(config => {
    const authToken = window.localStorage.getItem('authToken')
    const accessToken = `Token ${authToken}`
    if (accessToken) {
      config.headers.Authorization = accessToken
      return Promise.resolve(config)
    } else {
      return new Error('Unauthorized')
    }
  })
}
