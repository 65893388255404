import * as user from './user'
import * as article from './article'
import * as selects from './selects'
import * as result from './result'

export default {
  user,
  article,
  selects,
  result
}
