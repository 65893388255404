import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { notification, Tooltip } from 'antd'
import { copy2Clipboard } from '../../../../utils'
import { Modal, Heading, Preloader } from '../../../../components'
import { printSmall, shareSmall, downloadSmall, remove } from '../../../../resources/images'
import { SavedList } from './styled'

const defaultState = {
  visible: false,
  item: {}
}
const SavedResultList = props => {
  const {
    result: { list, isFetching },
    getUserHistoryRecord,
    removeHistoryRecord,
    ...rest
  } = props

  const { t } = useTranslation()
  const [state, setState] = useState({ ...defaultState })

  useEffect(() => {
    getUserHistoryRecord()
  }, [])

  const showModal = item => {
    setState({
      ...state,
      visible: true,
      item: item
    })
  }
  const handleRemoveSavedItem = () => {
    removeHistoryRecord({ record_id: item.id })
      .then(() => setState({ ...defaultState }))
  }

  const handleGetItemLink = (item, type) => {
    if (type === 'download') {
      window.open(item.pdf, '_target')
    } else {
      copy2Clipboard(item.pdf)
      notification.success({
        message: 'Link copied to clipboard'
      })
    }
  }

  const { visible, item } = state
  return (
    <>
      {isFetching
        ? <Preloader />
        : <>
          {list.length
            ? <SavedList
              dataSource={list}
              {...rest}
              renderItem={item =>
                <SavedList.Item
                  actions={[
                    <Tooltip key={1} placement='top' title={t('buttons:print')}>
                      <SavedList.Action onClick={() => handleGetItemLink(item, 'download')} src={printSmall} />
                    </Tooltip>,
                    <Tooltip key={2} placement='top' title={t('buttons:share')}>
                      <SavedList.Action key={2} onClick={() => handleGetItemLink(item, 'share')} src={shareSmall} />
                    </Tooltip>,
                    <Tooltip key={3} placement='top' title={t('buttons:download')}>
                      <SavedList.Action key={3} onClick={() => handleGetItemLink(item, 'download')} src={downloadSmall} />
                    </Tooltip>,
                    <Tooltip key={3} placement='top' title={t('buttons:remove')}>
                      <SavedList.Action key={4} onClick={() => showModal(item)} src={remove} />
                    </Tooltip>
                  ]}
                >
                  <SavedList.ItemTime>{moment(item.crated_at).format('DD.MM.YYYY')}</SavedList.ItemTime>
                  {JSON.parse(item.request).name}
                </SavedList.Item>}
            />
            : <Heading style={{ color: 'gray', fontSize: '1.5rem' }} level={4}>no_saved_results</Heading>}
        </>}
      {visible &&
        <Modal
          visible
          onOk={handleRemoveSavedItem}
          onCancel={() => setState({ ...state, visible: false })}
        >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Heading level={4}>do_you_want_to_delete</Heading>
            <Heading style={{ margin: '0 0 0 0.7rem' }} level={4}>{JSON.parse(item.request).name}</Heading>
          </div>
        </Modal>}
    </>
  )
}

export default SavedResultList
