import styled from 'styled-components'
import { Heading } from '../../../../../../components'

import { styleguide } from '../../../../../../constants'

const { colors } = styleguide

const Card = styled.div`
  padding: 5.6rem 5.6rem 8rem;
  text-align: center;
  border-radius: .6rem;
  background: ${colors.whisper};
  
`

Card.Title = styled(Heading)`
  && {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: bold;
    margin: 0;
  }
`

Card.Content = styled.div`
  margin: 4rem 0 3rem;
  min-height: 4rem;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: flex-end;
`

Card.Message = styled.p`
  font-size: 1.5rem;
  line-height: 1.6rem;
  font-weight: 600;
  margin: 0;
  padding-bottom: .6rem;
`

export {
  Card
}
