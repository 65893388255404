import React from 'react'

import { ArticleNumber, CalculationMethod, Inputs } from './steps'
import { Steps, ContentHeader, ContentHeaderActions } from '../../components'
import { Dashboard } from './styled'

const DashboardPage = ({ dashboard: { step } }) => {

  return (
    <Dashboard>
      <ContentHeader>
        <ContentHeaderActions isHidden actions={['open_saved_results']} />
        <Steps />
        <ContentHeaderActions actions={['open_saved_results']} />
      </ContentHeader>

      <Dashboard.StepContent>
        <Dashboard.Container>
          {step === 0 &&
            <CalculationMethod />}

          {step === 1 &&
            <ArticleNumber />}

          {step === 2 &&
            <Inputs />}
        </Dashboard.Container>
      </Dashboard.StepContent>

    </Dashboard>
  )
}

export default DashboardPage
