import { connect } from 'react-redux'
import actions from '../../../../store/actions'
import ArticleNumber from './ArticleNumber'

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  inputs: state.inputs
})

const mapDispatchToProps = dispatch => ({
  setArticleNumber: (data, params) => dispatch(actions.dashboard.setArticleNumber(data, params)),
  changeInput: data => dispatch(actions.inputs.changeInput(data)),
  changeStepWithParams: data => dispatch(actions.dashboard.changeStepWithParams(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleNumber)
