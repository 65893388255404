import * as t from '../types'
import { api } from '../../services'
import { isFetching } from './helpers'

const getSelectOptions = selects => dispatch => {
  dispatch(isFetching(t.GET_SELECT_OPTIONS_REQUEST, true))

  const promises = selects.map(select => api.selects.getSelectOptions(select))
  Promise.all(promises)
    .then(result => {
      const formattedData = result
        .reduce((acc, { data }, index) => {
          const select = selects[index]
          return { ...acc, [select]: Object.keys(data[select]).map(key => ({ key, value: data[select][key] })) }
        }, {})

      dispatch({
        type: t.GET_SELECT_OPTIONS_SUCCESS,
        payload: formattedData
      })
      dispatch({
        type: t.SET_RESULT_INPUTS,
        payload: Object.keys(formattedData)
          .reduce((acc, key) => ({ ...acc, [key]: formattedData[key][0].value }), {})
      })
      dispatch(isFetching(t.GET_SELECT_OPTIONS_REQUEST, false))
    })
}

export {
  getSelectOptions
}
