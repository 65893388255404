import { connect } from 'react-redux'
import actions from '../../store/actions'
import CalculatorTypes from './CalculatorTypes'

const mapStateToProps = state => ({
  calculator: state.calculator,
  options: state.options
})

const mapDispatchToProps = dispatch => ({
  changeActiveTab: data => dispatch(actions.calculator.changeActiveTab(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorTypes)
