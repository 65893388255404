import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { rgba } from 'polished'

import { styleguide } from '../../../../constants'

const { colors } = styleguide

const Calculator = styled.div`
  width: 100%;
  display: flex;
  border-radius: .6rem;
  box-shadow: 0 .4rem 1.6rem ${rgba(colors.black, 0.08)};
  background-color: ${colors.white};
  overflow: hidden;
`

Calculator.Aside = styled.aside`
  display: flex;
  flex-direction: column;
  padding-bottom: 6.4rem;
  background-color: ${colors.whisper};
  & > div {
    box-shadow: none;
  }
`

Calculator.ResetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-transform: capitalize;
  color: ${colors.main};
  padding-left: 3.2rem;
  cursor: pointer;
  max-width: 20rem;
  &:hover {
    color: ${colors.blue};
    * {
      fill: ${colors.blue};
    }
  }
`

Calculator.ResetButtonIcon = styled(ReactSVG)`
  margin-right: 1rem;
`

Calculator.Data = styled.div`
  width: 100%;
  padding: 0 1.6rem 2.6rem;
  box-shadow: 0 0 2.4rem ${rgba(colors.black, 0.08)};
  border-radius: .6rem;
  position: relative;
`

Calculator.Heading = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${colors.xanadu};
`

Calculator.TableContent = styled.div`
  padding: 2.4rem 1.8rem;
  background-color: ${colors.yellow};
  box-shadow: 0 0 2.4rem ${rgba(colors.black, 0.08)};
  border-radius: .6rem;
`
Calculator.TableContentTitle = styled.h2`
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.blue};
  letter-spacing: 0.05rem;
  margin-bottom: 1.6rem;
`

Calculator.DataFooter = styled.div`
  font-size: 1.2rem;
  line-height: 3.2rem;
  text-align: right;
  margin-top: 1.8rem;
  padding-right: 1.8rem;
  position: absolute;
  bottom: 0;
  right: 0;
`

export {
  Calculator
}
