import styled from 'styled-components'

const InputsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
 padding-bottom: 17rem;
`

InputsBlock.Content = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    margin-right: 3.2rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

export {
  InputsBlock
}
