import styled from 'styled-components'
import ReactSVG from 'react-svg'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  opacity: ${props => props.isHidden ? '0' : '1'};
  z-index: ${props => props.isHidden ? '-5' : '0'};
  
  & > div {
    margin-right: 3.2rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

Actions.Button = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${colors.white};
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    color: ${colors.yellow};
    * {
      fill: ${colors.yellow};
    }
  }
`

Actions.ButtonIcon = styled(ReactSVG)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1rem;
`

export {
  Actions
}
