import styled from 'styled-components'

const Dashboard = styled.div``

Dashboard.StepContent = styled.div`
  padding-top: 8rem;
`

Dashboard.Container = styled.div`
  max-width: 116rem;
  margin: 0 auto;
  padding: 0 1rem;
`

export {
  Dashboard
}
