import React from 'react'
import { useTranslation } from 'react-i18next'

import { AntHeading } from './styled'

const Heading = ({ children, ...rest }) => {
  const { t } = useTranslation()

  return (
    <AntHeading {...rest}>{t(`title:${children}`)}</AntHeading>
  )
}

export default Heading
