import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { rgba } from 'polished'
import { List } from 'antd'

import { styleguide } from '../../../../constants'

const { colors } = styleguide

const SavedList = styled(List)`
  && {
    width: 100%;
    max-width: 75.2rem;
    background-color: ${colors.white};
    box-shadow: 0 .4rem 1.6rem ${rgba(colors.black, 0.08)};
    
    .ant-list-items {
     .ant-list-item {
        padding: 1.6rem 2.4rem;
        line-height: 1.6rem;
        font-size: 1.2rem;
        font-weight: 600;
        &:hover {
          background-color: ${rgba(colors.mystic, 0.25)};
        }
     }
    }

    .ant-list-item-action-split {
      display: none;
    }

    .ant-list-item-action {
      li {
        padding: 0;
        margin-right: 2.4rem;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
`
SavedList.ItemTime = styled.span`
  font-size: 1.1rem;
  opacity: .6;
  margin-right: 4.8rem;
`

SavedList.Action = styled(ReactSVG)`
  width: 1.4rem;
  height: 1.4rem;
  * {
    fill: ${colors.dusty_gray}
  }
  &:hover {
    * {
      fill: ${colors.blue};
    }
  }
`

export {
  SavedList
}
