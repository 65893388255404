import { connect } from 'react-redux'
import Calculator from './Calculator'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  calculator: state.calculator,
  dashboard: state.dashboard,
  options: state.options,
  inputs: state.inputs
})

const mapDispatchToProps = dispatch => ({
  getResultData: (data, validateData, tab) => dispatch(actions.dashboard.getResultData(data, validateData, tab)),
  changeInput: data => dispatch(actions.inputs.changeInput(data)),
  changeSelect: data => dispatch(actions.inputs.changeSelect(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calculator)
