import styled from 'styled-components'

const ArticleNumberBlock = styled.div``

ArticleNumberBlock.CardBox = styled.div`
  max-width: 75rem;
  margin: 4.8rem auto 0 auto;
`

ArticleNumberBlock.Heading = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-align: center;
  margin-top: 2.4rem;
`

ArticleNumberBlock.Text = styled.p`
  font-size: 1.8rem;
  line-height: 2.4rem;
  text-align: center;
  font-weight: 600;
  margin: 2.4rem 0 4.8rem;
`

export {
  ArticleNumberBlock
}
