import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import ReactSVG from 'react-svg'
import { rgba } from 'polished'
import { Input, Select } from 'antd'

import { styleguide } from '../../constants'
const { colors } = styleguide
const { Option } = Select

const handleColorType = color => {
  switch (color) {
    case 'white':
      return colors.white
    default:
      return colors.main
  }
}

const InputBlock = styled.div`
  width: 100%;
  position: relative;
`

const InputDescription = styled.div`
  margin-bottom: .8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

InputDescription.Error = styled.span`
  position: absolute;
  top: 100%;
  left: 0;
  color: ${colors.burnt_sienna};
  font-size: 1.4rem;
  white-space: pre;
`

InputDescription.Label = styled.div`
  font-size: 1.5rem;
  line-height: 1.6rem;
  font-weight: 600;
  color: ${colors.main};
`

InputDescription.Icon = styled(ReactSVG)`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  * {
    fill: ${colors.main}
  }
`

const AntInput = styled(Input)`
  && {
    width: 100%;
    border-radius: .6rem;
    background-color: ${colors.white}
    border: ${props => props.error ? `.1rem solid colors.burnt_sienna` : 'none'};
    color: ${({ color }) => handleColorType(color)};
    &::placeholder {
      font-size: 1.3rem;
      color: ${({ color }) => rgba(handleColorType(color), 0.6)};
    }
  }
`

const AntNumber = styled(NumberFormat)`
  width: 100%;
  && {
    width: 100%;
    height: 4rem;
    color: ${({ color }) => handleColorType(color)};
    font-size: 1.6rem;
    text-transform: capitalize;
    line-height: 2.4rem;
    padding: .6rem 1.1rem;
    border: ${props => props.error ? `.1rem solid ${colors.burnt_sienna}` : 'none'};
    border-radius: .6rem;
    background-color: ${({ error }) => error ? rgba(colors.burnt_sienna, 0.15) : colors.white};
    &:focus,
    &:active,
    &:hover {
      outline: none;
    }
    &::placeholder {
      font-size: 1.3rem;
      color: ${colors.main};
    }
  }
`

const AntSelect = styled(Select)`
  && {
    width: 100%;
    color: ${colors.main};
    font-size: 1.3rem;
    .ant-select-selection {
      border: ${props => props.error ? `.1rem solid  ${colors.burnt_sienna}` : 'none'};
      background-color: ${colors.white}
    }
    .ant-select-arrow {
      i {
        color: ${colors.black};
      }
    }
  }
`

AntSelect.Option = styled(Option)``
export {
  InputBlock,
  InputDescription,
  AntInput,
  AntNumber,
  AntSelect
}
