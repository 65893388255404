import React from 'react'

import { Head } from './styled'
const ContentHeader = ({ children, ...rest }) => {
  return (
    <Head {...rest}>
      <Head.Container>
        <Head.Content>{children}</Head.Content>
      </Head.Container>
    </Head>
  )
}

export default ContentHeader
