import * as t from '../types'

const initialState = {
  tab: 0,
  recommended_products: [],
  single_product: [],
  calculation_result: {},
  result_inputs: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.CHANGE_ACTIVE_TAB:
      return {
        ...state,
        tab: action.payload.tab,
        recommended_products: [],
        single_product: [],
        calculation_result: {},
        result_inputs: {}
      }
    case t.CHOOSE_ACTIVE_TAB:
      return {
        ...state,
        tab: action.payload
      }
    case t.SET_RESULT_INPUTS:
      return {
        ...state,
        result_inputs: {
          ...state.result_inputs,
          ...action.payload
        }
      }
    case t.GET_RESULT_DATA_REQUEST:
      return {
        ...state,
        isFetching: action.payload
      }
    case t.GET_RESULT_DATA_SUCCESS:
      return {
        ...state,
        recommended_products: action.payload[0].products,
        calculation_result: action.payload[1].status ? action.payload[1] : {
          edge_distances: {},
          more: {
            basic: {},
            advanced: {}
          }
        },
        single_product: Object.keys(action.payload[2].product).length ? [action.payload[2].product] : action.payload[2].product
      }
    case t.GET_RESULT_DATA_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case t.RESET_CALCULATOR: {
      return {
        tab: action.payload.tab,
        recommended_products: [],
        single_product: [],
        calculation_result: {},
        result_inputs: {}
      }
    }
    case t.HANDLE_SELECT_CHANGE: {
      return {
        ...state,
        recommended_products: [],
        single_product: [],
        calculation_result: {},
        result_inputs: {}
      }
    }
    case t.HANDLE_INPUT_CHANGE: {
      return {
        ...state,
        recommended_products: [],
        single_product: [],
        calculation_result: {},
        result_inputs: {}
      }
    }
    case t.BACK_TO_START:
      return {
        ...initialState
      }
    default:
      return state
  }
}
