import React from 'react'
import { useTranslation } from 'react-i18next'

import { i18n } from '../../services'
import { LanguageSelector } from '../'
import { HeaderBlock } from './styled'
import { logo } from '../../resources/images'

const Header = () => {
  const { t } = useTranslation()

  const getLinkByLanguage = language => {
    switch (language) {
      case 'en': return 'https://www.rampa.com/de/en/'
      case 'de': return 'https://www.rampa.com/de/de/'
      default: return 'https://www.rampa.com/de/en/'
    }
  }

  return (
    <HeaderBlock>
      <HeaderBlock.Container>
        <HeaderBlock.Row>
          <HeaderBlock.Merch>
            <HeaderBlock.MerchLogo
              src={logo}
              onClick={() => window.open(getLinkByLanguage(i18n.language.split('-')[0]), '_blank')}
            />
          </HeaderBlock.Merch>

          <HeaderBlock.Div>
            <HeaderBlock.Link
              title={t('online_shop')}
              onClick={() => window.open(getLinkByLanguage(i18n.language.split('-')[0]), '_blank')}
            >
              {t('online_shop')}
            </HeaderBlock.Link>
          </HeaderBlock.Div>

          <HeaderBlock.Div style={{ width: '18.3rem' }}>
            <LanguageSelector style={{ width: '100%' }} />
          </HeaderBlock.Div>
        </HeaderBlock.Row>
      </HeaderBlock.Container>
    </HeaderBlock>
  )
}

export default Header
