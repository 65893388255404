import React from 'react'

import { i18n } from '../../services'
import { usa, de } from '../../resources/images'
import { LanguageSwitcher } from './styled'

const languages = [
  {
    title: 'English',
    value: 'en',
    icon: usa
  },
  {
    title: 'Deutsch',
    value: 'de',
    icon: de
  }
]

export default function LanguageSelector ({ ...rest }) {
  const handleChangeLanguage = value => {
    i18n.changeLanguage(value)
  }

  const filteredLanguageValue = language => {
    switch (language) {
      case 'en': return 'en'
      case 'de': return 'de'
      default: return 'en'
    }
  }

  return (
    <LanguageSwitcher className='switcher' onChange={handleChangeLanguage} value={filteredLanguageValue(i18n.language.split('-')[0])} {...rest}>
      {languages.map(i =>
        <LanguageSwitcher.Option
          key={i.value}
          value={i.value}
        >
          <LanguageSwitcher.Content>
            <LanguageSwitcher.Icon src={i.icon} />
            {i.title}
          </LanguageSwitcher.Content>
        </LanguageSwitcher.Option>
      )}
    </LanguageSwitcher>
  )
}
