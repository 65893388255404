import { connect } from 'react-redux'
import actions from '../../../../store/actions'
import Inputs from './Inputs'

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  options: state.options,
  calculator: state.calculator,
  inputs: state.inputs
})

const mapDispatchToProps = dispatch => ({
  getResultData: (data, validateData, tab) => dispatch(actions.dashboard.getResultData(data, validateData, tab)),
  changeInput: data => dispatch(actions.inputs.changeInput(data)),
  changeSelect: data => dispatch(actions.inputs.changeSelect(data)),
  setErrorInput: data => dispatch(actions.inputs.setErrorInput(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inputs)
