import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { selects } from '../../constants'
import { Preloader } from '../'
import { Section } from './styled'

const { selectsType } = selects

const PrivateRoute = ({ component: Component, options: { options }, getUser, getSelectOptions, ...rest }) => {
  useEffect(() => {
    if (window.localStorage.getItem('authToken')) {
      getSelectOptions(selectsType)
    }
  }, [])

  return <Route
    {...rest}
    render={props =>
      window.localStorage.getItem('authToken')
        ? <>
          {Object.keys(options).length !== 0
            ? <Section>
              <Component {...props} />
            </Section>
            : <Preloader />}
        </>
        : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
  />
}

export default PrivateRoute
