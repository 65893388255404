import styled from 'styled-components'
import { rgba } from 'polished'
import { Select } from 'antd'

import { styleguide } from '../../constants'

const { Option } = Select
const { colors } = styleguide

const LanguageSwitcher = styled(Select)`
  && {
    margin-top: 1.6rem;
    width: 100%;
    color: ${colors.main};
    font-weight: 500;
    font-size: 1.3rem;
    text-transform: capitalize;
    .ant-select-selection {
      border: .1rem solid ${rgba(colors.black, 0.1)};
      background-color: ${colors.white}
    }
    .ant-select-arrow {
      i {
        color: ${colors.black};
      }
    }
    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: ${rgba(colors.black, 0.12)} !important;
    }
  }
`

LanguageSwitcher.Option = styled(Option)`
  && {
    text-transform: capitalize;
  }
`

LanguageSwitcher.Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

LanguageSwitcher.Icon = styled.div`
  width: 1.6rem;
  height: 1.1rem;
  margin-right: .8rem;
  background: url(${props => props.src}) center no-repeat;
  background-size: cover;
`
export {
  LanguageSwitcher
}
