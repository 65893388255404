import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { controls } from '../../constants'

import { Modal, Heading, Button } from '../'
import { notification } from 'antd'
import {
  InputBlock,
  AntInput,
  AntNumber,
  AntSelect,
  InputDescription
} from './styled'
import { info } from '../../resources/images'

const InputGroup = props => {
  const {
    type,
    options = [],
    format,
    label,
    placeholder,
    withIcon,
    description = {},
    value,
    withErrorDescr,
    color = '',
    inputs: { app_selects, app_inputs, errors },
    validations,
    onChange,
    ...rest
  } = props

  const [visible, setVisible] = useState(false)
  const validationRules = controls.appSelectsValidationObj[label] || {}

  const check = (item, rules) => Object.keys(rules)
    .reduce((acc, key) => {
      const keyValue = app_selects[key]
      const availableValues = rules[key][keyValue]
      if (!keyValue || !availableValues) {
        return acc
      }
      if (Array.isArray(availableValues)) {
        return acc && availableValues.includes(item)
      }
      return acc && check(item, availableValues)
    }, true)

  const availableOptions = options.filter(i => check(i.value, validationRules))

  useEffect(() => {
    if (type === 'select' && !check(app_selects[label], validationRules)) {
      if (label === 'angle_beta') {
        onChange(options[options.length - 1].value)
      } else {
        onChange(options[0].value)
      }
      notification.warning({
        message: `${t(`select_label:${label}`)} ${t('notifications:was_changed')}`
      })
    }
  }, Object.keys(app_selects).map(key => app_selects[key]))

  const { t } = useTranslation()
  return (
    <>
      <InputBlock>
        {!!label && !withErrorDescr &&
          <InputDescription>
            {errors[label] &&
              <InputDescription.Error>
                {t(`inputs_error:${label}`)}
              </InputDescription.Error>}
            <InputDescription.Label
              color={color}
            >
              {type === 'number'
                ? <>
                  {label === 'target_load_capacity'
                    ? <>
                      {t(`input_label:${label}`)}
                      <span style={{ fontWeight: 'normal', paddingLeft: '.5rem' }}>(in N)</span>
                    </>
                    : t(`input_label:${label}`)}
                </>
                : t(`select_label:${label}`)}
            </InputDescription.Label>

            {withIcon &&
              <InputDescription.Icon
                color={color}
                src={info}
                onClick={() => setVisible(true)}
              />}

          </InputDescription>}

        {type === 'text' &&
          <AntInput
            onChange={onChange}
            color={color}
            {...rest}
          />}

        {type === 'number' &&
          <AntNumber
            onChange={onChange}
            value={app_inputs[label]}
            placeholder={t(`input_label:${placeholder}`)}
            color={color}
            error={errors[label] ? 1 : 0}
            format={format}
            {...rest}
          />}

        {type === 'select' &&
          <AntSelect
            value={app_selects[label]}
            color={color}
            onChange={v => onChange(v)}
            {...rest}
          >
            {availableOptions.map(option =>
              <AntSelect.Option
                key={option.value}
              >
                {t(`options:${option.value}`)}
              </AntSelect.Option>)}
          </AntSelect>}
      </InputBlock>
      {visible &&
        <Modal
          visible
          onCancel={() => setVisible(false)}
          description={description}
          footer={[
            <div style={{ maxWidth: '50%', margin: '0 auto' }}>
              <Button
                type='primary'
                onClick={() => setVisible(false)}
              >Ok
              </Button>
          </div>
          ]}
        >
          <Heading level={4}>{t(`select_label:${label}`)}</Heading>
        </Modal>}
    </>
  )
}

export default InputGroup
