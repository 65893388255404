import React from 'react'
import { useTranslation } from 'react-i18next'

import { CalculationResult } from './styled'

const CalculationList = ({ title = [], data = {}, single, ...rest }) => {
  const { t } = useTranslation()

  return (
    <CalculationResult single={single ? 1 : 0} {...rest}>
      {!!title &&
        <CalculationResult.Title>
          {title[0] &&
            title[0]}&nbsp;
          <span style={{ fontWeight: 'normal' }}>(in mm)</span>&nbsp;
          {title[1] &&
            title[1]}
        </CalculationResult.Title>}
      <CalculationResult.List row={Math.ceil(Object.keys(data).length / 2)} single={single ? 1 : 0}>
        {Object.keys(data).map(i => {
          if (Math.round(data[i]) !== -1) {
            return (<CalculationResult.Item key={i} single={single ? 1 : 0}>
              {!single &&
                <CalculationResult.ItemContent>
                  {t(`table:${i}`)}
                </CalculationResult.ItemContent>}
              <CalculationResult.ItemContent>{Math.round(data[i])}</CalculationResult.ItemContent>
            </CalculationResult.Item>)
          } else {
            return null
          }
        }
        )}
      </CalculationResult.List>
    </CalculationResult>
  )
}

export default CalculationList
