import styled from 'styled-components'
import { Button } from 'antd'
import { linearGradient, rgba } from 'polished'
import { styleguide } from '../../constants'

const { colors } = styleguide

const handleGradientType = bool => {
  if (bool) {
    return linearGradient({
      colorStops: [`${colors.yellow} 0%`, `${colors.yellow} 98.74%`],
      toDirection: '45deg',
      fallback: colors.yellow
    })
  } else {
    return linearGradient({
      colorStops: [`${colors.chambray} 0%`, `${colors.blue} 100%`],
      toDirection: '45deg',
      fallback: colors.blue
    })
  }
}

const AntdButton = styled(Button)`
  && {
    width: 100%;
    color: ${props => props.orange ? colors.main : colors.white};
    font-size: ${props => props.large ? '2.2rem' : '1.6rem'};
    line-height: 2.2rem;
    height: auto;
    font-weight: ${props => props.large ? 'bold' : '600'};
    letter-spacing: .1rem;
    text-transform: uppercase;
    box-shadow: 0 .8rem 1.6rem ${rgba(colors.black, 0.12)};
    ${({ orange }) => handleGradientType(orange)};
    padding: ${props => props.large ? '1.7rem 0' : '1rem 0'} !important;
    border: none;
    &[disabled] {
      background-image: none;
    }
    &:active,
    &:hover,
    &:focus {
      background-color: ${props => props.orange ? colors.yellow : colors.blue};
      opacity: .9;
      color: ${props => props.orange ? colors.main : colors.white};
      border: none;
    }
  }
`

export {
  AntdButton
}
