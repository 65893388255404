import { connect } from 'react-redux'
import actions from '../../store/actions'
import ContentHeaderActions from './ContentHeaderActions'

const mapStateToProps = state => ({
  calculator: state.calculator,
  inputs: state.inputs,
  options: state.options
})

const mapDispatchToProps = dispatch => ({
  saveHistoryRecord: data => dispatch(actions.result.saveHistoryRecord(data)),
  backToStart: data => dispatch(actions.calculator.backToStart(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentHeaderActions)
