import styled from 'styled-components'

import { styleguide } from '../../../../../../constants'

const { colors } = styleguide

const CalculationResult = styled.div`
  margin-top: ${({ single }) => single ? '0' : '3.2rem'};
  &.test {
    ul {
      li {
        background-color: transparent;
        border-bottom: ${({ single }) => single ? 'none' : `.1rem solid ${colors.gray}`};
      }
    }
  }
`

CalculationResult.Title = styled.h4`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.4rem;
  padding: 1.6rem 0 .8rem;
  margin-bottom: 0;
  text-align: center;
  border-radius: .6rem .6rem 0 0;
  background: ${colors.whisper};
`

CalculationResult.List = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${({ single }) => single ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'};
  grid-template-rows: ${({ single, row }) => single ? 'repeat(1, 1fr)' : `repeat(${row}, 1fr)`};
  grid-column-gap: 0;
  grid-row-gap: 0;
  padding: 0;
  list-style: none;
  width: 100%;
`

CalculationResult.Item = styled.li`
  display: flex;
  padding: 1.6rem 0;
  border-bottom: ${({ single }) => single ? 'none' : `.1rem solid ${colors.gray}`};
    &:nth-of-type(2),
    &:nth-of-type(4),
    &:nth-of-type(7),
    &:nth-of-type(9) {
      background-color: ${colors.alabaster};
    }
    &:nth-of-type(5),
    &:nth-of-type(10) {
      border: none;
    }
`

CalculationResult.ItemContent = styled.span`
  flex-basis: ${({ single }) => single ? '50%' : '100%'};
  text-align: center;
`

export {
  CalculationResult
}
