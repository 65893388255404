import styled from 'styled-components'
import { rgba } from 'polished'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Result = styled.div``

Result.Content = styled.div`
  padding-top: 8rem;
`

Result.Container = styled.div`
  max-width: 116rem;
  margin: 0 auto;
  padding: 0 1rem;
`

Result.Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

Result.SavedList = styled.ul`
  max-width: 75.2rem;
  list-style: none;
  padding: 0;
  margin: 5.6rem 0 0;
  border-radius: .6rem;
  box-shadow: 0 .4rem 1.6rem ${rgba(colors.black, 0.08)};
`
Result.SavedItem = styled.li`
  padding: 1.6rem 2.4rem;
  &:hover {
    background-color: ${rgba(colors.mystic, 0.25)};
  }
`
Result.SavedTime = styled.span``

export {
  Result
}