import { combineReducers } from 'redux'
import * as t from '../types'

import dashboard from './dashboard'
import options from './options'
import calculator from './calculator'
import inputs from './inputs'
import result from './result'

const appReducer = combineReducers({
  dashboard,
  options,
  inputs,
  calculator,
  result
})

const rootReducer = (state, action) => {
  if (action.type === t.USER_LOGOUT) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
