import http from './http'

const getPDFLink = data => {
  return http({
    url: 'pdf',
    method: 'POST',
    data
  })
}

const saveHistoryRecord = data => {
  return http({
    url: 'history',
    method: 'POST',
    data
  })
}

const getUserHistoryRecord = () => {
  return http({
    url: 'history',
    method: 'GET'
  })
}

const removeHistoryRecord = data => {
  return http({
    url: 'history/delete',
    method: 'POST',
    data
  })
}

export {
  getPDFLink,
  getUserHistoryRecord,
  saveHistoryRecord,
  removeHistoryRecord
}
