import styled from 'styled-components'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Head = styled.div`
  background: ${colors.blue};
`

Head.Container = styled.div`
  max-width: 116rem;
  margin: 0 auto;
  padding: 0 1rem;
`

Head.Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export {
  Head
}
